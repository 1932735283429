import { Button } from '@material-ui/core';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { isPast } from 'date-fns';
import { getZoomAuthToken } from '../services';
import AuthWithZoom from './AuthWithZoom';

const ZoomLoginButton = forwardRef(({ isMultiAccounts, isDeauthorized, zoomQuery }, ref) => {
  const { data, refetch, isLoading } = zoomQuery;
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;
  const accountId = user.contaId;
  const loginModalRef = useRef(null);
  const tokenExpired = isPast(data?.tokenExpiresIn);
  const [canSignin, setCanSignin] = useState(isDeauthorized || tokenExpired);
  const onSignInWithZoomHandler = () => {
    loginModalRef.current.openModal();
  };
  useImperativeHandle(
    ref,
    () => ({
      updateState: value => {
        setCanSignin(value);
      }
    }),
    []
  );
  const onConfirmLoginModal = async () => {
    if (isMultiAccounts) loginModalRef.current.closeModal();
    const isLoggedIn = await getZoomAuthToken(accountId, isMultiAccounts, data?.length || 0);
    if (isLoggedIn && isMultiAccounts) {
      setCanSignin(false);
      await refetch();
    }
  };
  const isDisabled = (isMultiAccounts && !canSignin) || isLoading;
  return (
    <>
      <Button
        color="primary"
        disabled={isDisabled}
        size="large"
        sx={{ height: 37, width: '100%', '&:disabled': { color: 'common.white', bgcolor: 'muted' } }}
        onClick={onSignInWithZoomHandler}
      >
        Integração
      </Button>
      <AuthWithZoom
        isMultiAccounts={isMultiAccounts}
        ref={loginModalRef}
        buttonConfirmTitle="Adicionar integração"
        title="Integração Zoom"
        description="Integre as gravações feitas em sua conta Zoom na biblioteca de conteúdos Spalla."
        onConfirm={onConfirmLoginModal}
      />
    </>
  );
});

export default ZoomLoginButton;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ListRecords from './pages/ListRecords';

const RecordsModule = () => (
  <Switch>
    <Route path="/records" exact component={ListRecords} />
  </Switch>
);
export default RecordsModule;

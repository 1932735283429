import { useConfirmationDialog } from '@app/components/ConfirmDialog';
import { TaskAlt as TaskAltIcon } from '@material-ui/icons';
import { Stack, Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import addNotification from '../../../utils/addNotification';
import { cancelTencentIntegration } from '../services';
import AuthWithZoom from './AuthWithZoom';
import CancelIntegrationButton from './CancelIntegrationButton';

const TencentLogoutButton = ({ disabled, setTencentIntegrationIsActive, setTencentImportIsActive, isLoading }) => {
  const logoutModalRef = useRef(null);
  const getConfirmation = useConfirmationDialog();
  const onSignInWithZoomHandler = () => {
    logoutModalRef.current.openModal();
  };
  const onConfirmLogoutModal = async () => {
    try {
      await cancelTencentIntegration();
      await getConfirmation({
        title: (
          <Stack alignItems="center" direction="row" gap={2}>
            <TaskAltIcon color="success" />
            Sucesso
          </Stack>
        ),
        message: (
          <Typography>
            A integração com o <b>Tecent Cloud</b> foi desfeita com sucesso.
          </Typography>
        ),
        cancelButtonProps: { sx: { display: 'none' } },
        confirmButtonProps: { children: 'Fechar' }
      });
      logoutModalRef.current.closeModal();
      setTencentIntegrationIsActive(prevState => !prevState);
      setTencentImportIsActive(false);
    } catch {
      addNotification({
        message: 'Erro ao tentar desfazer a integração.',
        type: 'danger'
      });
    }
  };
  return (
    <>
      <CancelIntegrationButton disabled={isLoading || disabled} onClick={onSignInWithZoomHandler} />
      <AuthWithZoom
        ref={logoutModalRef}
        buttonConfirmTitle="Desfazer integração"
        title="Desfazer integração Tecent Cloud"
        description={
          <Typography>
            Você tem certeza que deseja desfazer esta integração? Para integrar novamente, será necessário adicionar a <b>Chave secreta</b> e o{' '}
            <b>ID secreto</b>.
          </Typography>
        }
        onConfirm={onConfirmLogoutModal}
      />
    </>
  );
};

export default TencentLogoutButton;

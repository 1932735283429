import { isArray, isString } from 'lodash';

const authorizationStrategy = (permissions, requirement) => {
  const isSingleRole = isString(requirement);

  if (isSingleRole) {
    const hasPermission = permissions.includes(requirement);
    return hasPermission;
  }

  const isMultipleRoles = isArray(requirement);

  if (isMultipleRoles) {
    const hasPermission = requirement.some(req => permissions.includes(req));
    return hasPermission;
  }

  throw new Error('Cannot determine role requirements');
};

export default authorizationStrategy;

import { createSlice } from '@reduxjs/toolkit';

const initialAtendimentosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  thumbnail: process.env.REACT_APP_API_URL + 'api/media/placeholder.png',
  geo_info: {
    country: 'Brasil',
    state: 'Rio Grande do Sul',
    city: 'Porto Alegre'
  },
  liveDetails: {
    _id: undefined,
    hora: new Date().getTime(),
    locations: [],
    allowed_refer: [],
    status: null,
    scheduledStartTime: new Date(),
    transcode: {
      enableLive2vod: false,
      enableDvr: false,
      enableAudioOnly: false
    },
    security: {
      geoAccessControl: '',
      geoRegionList: [],
      referrerWhitelist: []
    },
    assets: {
      thumbnail: '',
      startSoonScreen: '',
      endingScreen: '',
      pauseScreen: '',
      playerColor: ''
    }
  },
  oldLiveDetails: {
    _id: undefined,
    hora: new Date().getTime(),
    locations: [],
    allowed_refer: [],
    status: null
  },
  live: {
    id: null,
    live: {
      id: null,
      status: null,
      title: '',
      thumbnail: 'https://static-wp-tor15-prd.torcedores.com/wp-content/uploads/2019/11/flamengo-e-fluminense-563x338.png',
      url: ''
    },
    tech_info: {
      device: '',
      os: '',
      app: '',
      network_provider: ''
    },
    geo_info: {
      country: 'Brasil',
      state: 'Rio Grande do Sul',
      city: 'Porto Alegre'
    },
    service_history: []
  },
  player_events: [],
  lastError: null
};
export const callTypes = {
  list: 'atendimento/list',
  action: 'action'
};

export const atendimentosSlice = createSlice({
  name: 'atendimentos',
  initialState: initialAtendimentosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    eventosFetched: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.player_events = action.payload;
    },
    telemetryFetched: (state, action) => {
      const data = action.payload;
      state.listLoading = false;
      state.error = null;
      state.live = data;
    },
    liveFetched: (state, action) => {
      const data = action.payload;
      state.listLoading = false;
      state.error = null;
      state.liveDetails = data;
    },
    oldLiveFetched: (state, action) => {
      const data = action.payload;
      state.listLoading = false;
      state.error = null;
      state.oldLiveDetails = data;
    }
  }
});

import { FormHelperText, OutlinedInput, Stack, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import useParams from '../../../../../utils/useParams';
import validationSchema from './providers/validationSchema';
import { updatePassword } from './services';
import SuccessfullyUpdatedPassword from './SuccessfullyUpdatedPassword';

const formHelperTextProps = { color: 'error' };

const NewPassword = () => {
  const [showPassword, setShowPassword] = useState({
    password: false,
    repeatedPassword: false
  });
  const params = useParams();
  const { email, token, hash } = Object.fromEntries(params);
  const [changing, setChanging] = useState(false);
  const [passwordChanged, setChangePassword] = useState(false);
  const enableLoading = () => setChanging(true);
  const disableLoading = () => setChanging(false);
  const updateChangedPasswordStatus = () => setChangePassword(true);
  const form = useFormik({
    initialValues: {
      password: '',
      repeatedPassword: ''
    },
    validationSchema,
    onSubmit: async values => {
      enableLoading();
      try {
        await updatePassword({ email, token, hash, newPassword: values.password });
        updateChangedPasswordStatus();
      } catch {
        form.setErrors({ repeatedPassword: 'Ocorreu um erro. Tente novamente' });
      } finally {
        disableLoading();
      }
    }
  });
  const hasErrors = fieldName => form.touched[fieldName] && form.errors[fieldName];
  if (passwordChanged) {
    return <SuccessfullyUpdatedPassword />;
  }
  const toggleShowPassword = inputKey => {
    setShowPassword(prevState => {
      const newState = { ...prevState };
      newState[inputKey] = !newState[inputKey];
      return newState;
    });
  };
  const showPasswordByInput = inputKey => showPassword[inputKey];
  const renderIcon = inputKey => {
    const Icon = showPassword[inputKey] ? Visibility : VisibilityOff;
    return <Icon sx={{ cursor: 'pointer' }} onClick={() => toggleShowPassword(inputKey)} />;
  };
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 65px)', width: '100%' }}>
      <Stack alignItems="center" sx={{ px: { xs: 3, md: 0 }, width: { xs: '100%', md: 420 } }}>
        <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>Nova senha</Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 16, mt: { xs: 2, md: 3 }, textAlign: 'center' }}>
          Escolha uma senha que seja segura e tenha entre 4 e 16 caracteres.
        </Typography>
        <form onSubmit={form.handleSubmit} style={{ width: '100%' }}>
          <OutlinedInput
            data-testid="newPassword"
            name="password"
            value={form.values.password}
            endAdornment={renderIcon('password')}
            type={showPasswordByInput('password') ? 'text' : 'password'}
            placeholder="Nova senha"
            sx={{ height: 37, mt: 4, width: '100%' }}
            onChange={form.handleChange}
          />
          {hasErrors('password') && (
            <FormHelperText data-testid="passwordResetError" {...formHelperTextProps}>
              {form.errors.password}
            </FormHelperText>
          )}
          <OutlinedInput
            data-testid="repeatNewPassword"
            name="repeatedPassword"
            value={form.values.repeatedPassword}
            endAdornment={renderIcon('repeatedPassword')}
            type={showPasswordByInput('repeatedPassword') ? 'text' : 'password'}
            placeholder="Repetir senha"
            sx={{ height: 37, mt: 2, width: '100%' }}
            onChange={form.handleChange}
          />
          {hasErrors('repeatedPassword') && (
            <FormHelperText data-testid="repeatPasswordResetError" {...formHelperTextProps}>
              {form.errors.repeatedPassword}
            </FormHelperText>
          )}
          <LoadingButton data-testid="newPasswordBTN" loading={changing} type="submit" sx={{ mt: 5, width: '100%' }}>
            Atualizar senha
          </LoadingButton>
        </form>
      </Stack>
    </Stack>
  );
};

export default NewPassword;

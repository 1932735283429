import useParams from '@app/utils/useParams';
import React, { createContext, useState } from 'react';

export const Context = createContext();

const ContextProvider = ({ children }) => {
  const params = useParams();
  const liveId = params.get('liveId') || '';
  const [arenaUser, setArenaUser] = useState(JSON.parse(localStorage.getItem('arenaUser')));
  return <Context.Provider value={{ arenaUser, setArenaUser, liveId }}>{children}</Context.Provider>;
};

export default ContextProvider;

import { Button, Stack, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, MarkEmailRead as MarkEmailReadIcon } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const SuccessfullyUpdatedPassword = () => (
  <Stack alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 65px)', width: '100%' }}>
    <Stack
      alignItems="center"
      sx={{
        borderRadius: 1,
        minHeight: { xs: 'auto', md: 385 },
        px: { xs: 4, md: 4 },
        py: { xs: 0, md: 5 },
        width: { xs: '100%', md: 468 }
      }}
    >
      <MarkEmailReadIcon color="primary" sx={{ height: 60, mb: 3, width: 60 }} />
      <Typography data-testid="passwordUpdateSuccessMessage" sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>
        Senha atualizada com sucesso!
      </Typography>
      <Typography sx={{ color: 'neutral.50', fontSize: 16, mt: { xs: 2, md: 4 }, textAlign: 'center' }}>
        Volte para o inicio e acesse a plataforma com a sua nova senha.
      </Typography>
      <Link to="/auth/login" style={{ marginTop: 5, width: '100%' }}>
        <Button data-testid="backToLoginScreenRecoveredPasswordBTN" startIcon={<ArrowBackIcon />} size="large" sx={{ width: '100%' }}>
          Voltar para o início
        </Button>
      </Link>
    </Stack>
  </Stack>
);

export default SuccessfullyUpdatedPassword;

import { Box, Button, Divider, Modal, Stack, Typography } from '@material-ui/core';
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons';
import React from 'react';

const ConfirmationDialog = ({ open, title, message, cancelButtonProps, confirmButtonProps, onConfirm, onDismiss }) => (
  <Modal open={open} onClose={onDismiss}>
    <Box
      sx={{
        bgcolor: 'background.paper',
        borderRadius: 1,
        boxShadow: 24,
        left: '50%',
        p: 4,
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography sx={{ color: 'neutral.dark', fontSize: 16 }}>{title}</Typography>
        <CloseRoundedIcon sx={{ color: 'muted', cursor: 'pointer' }} onClick={onDismiss} />
      </Stack>
      <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />
      <Typography sx={{ color: 'neutral.body', fontSize: 14 }}>{message}</Typography>
      <Divider sx={{ bgcolor: 'grey.200', mb: 3, mt: 6 }} />
      {!onConfirm ? (
        <Button onClick={onDismiss}>Fechar</Button>
      ) : (
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button variant="outlined" {...cancelButtonProps} onClick={onDismiss}>
            Cancelar
          </Button>
          <Button onClick={onConfirm} {...confirmButtonProps}>
            {confirmButtonProps?.children || 'Confirmar'}
          </Button>
        </Stack>
      )}
    </Box>
  </Modal>
);

export default ConfirmationDialog;

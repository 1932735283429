import { List, ListItem, ListItemText } from '@material-ui/core';
import isSameRange from '../utils/isSameRange';
import React from 'react';

const DefinedRanges = ({ ranges, selectedRange, setRange }) => {
  return (
    <List>
      {ranges.map((range, idx) => (
        <ListItem button key={idx} onClick={() => setRange(range)}>
          <ListItemText
            primaryTypographyProps={{
              variant: 'body2',
              sx: {
                fontWeight: isSameRange(range, selectedRange) ? 'bold' : 'normal'
              }
            }}
          >
            {range.label}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

export default DefinedRanges;

import axios from 'axios';

export const shutdownLive = liveId => axios.put(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}/control/complete`);
export const startLive = liveId => axios.put(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}/control/start`);
export const pauseLive = liveId => axios.put(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}/control/pause`);
export const resumeLive = liveId => axios.put(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}/control/resume`);
export const getLives = ({ accountId, page, term, status, sortDirection, sortBy }) =>
  axios.get(
    `${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams?accountId=${accountId}&sort=${sortBy},${sortDirection}&page=${page}&limit=10&title=${term}&status=${status}&shutdown=false`
  );
export const getClients = () => axios.get('api/cliente?subConta=false');
export const deleteLive = liveId => axios.delete(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}`);
export const getTeams = (accountId = '') =>
  axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v2/accounts?accountId=${accountId}`).then(({ data }) => data);
export const updateLive = (liveId, form) => axios.patch(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}`, form);
export const getPresets = () => axios.get(`${process.env.REACT_APP_NOVA_API_URL}/bff/lives/preset`);
export const sendCSV = csv => axios.post(`${process.env.REACT_APP_NOVA_API_URL}/bff/whereby/create-csv-rooms`, csv);

import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import * as auth from '@app/modules/Auth/_redux/authRedux';
import { atendimentosSlice } from '@app/modules/Support/_redux/atendimentosSlice';
import { analyticsSlice } from '@app/modules/Analytics/_redux/analyticsSlice';
import uploadFile from '@app/redux/upload/uploadFile.reducer';
export const rootReducer = combineReducers({
  auth: auth.reducer,
  atendimento: atendimentosSlice.reducer,
  analytics: analyticsSlice.reducer,
  uploadFile,
});
export function* rootSaga() {
  yield all([auth.saga()]);
}

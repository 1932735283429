import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    password: yup
      .string()
      .min(4)
      .max(16)
      .required(),
    repeatedPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Senhas incompatíveis')
      .required()
  });

export default validationSchema;

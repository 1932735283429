import React from 'react';
import { createIntl, createIntlCache, IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja';
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh';

import { useLang } from './Metronici18n';
import ptBrMessages from './messages/pt-br';

const allMessages = {
  'pt-br': ptBrMessages,
};

export const intl = createIntl(
  {
    locale: 'pt-br',
    messages: allMessages['pt-br'],
  },
  createIntlCache(),
);

export function I18nProvider({ children }) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

import {
  addDays,
  endOfMonth,
  endOfWeek,
  isBefore,
  isSameDay,
  isValid,
  isWithinInterval,
  parseISO,
  startOfMonth,
  startOfWeek,
  toDate
} from 'date-fns';

export const identity = x => x;

export const chunks = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (v, i) => array.slice(i * size, i * size + size));
};

export const combine = (...args) => args.filter(identity).join(' ');

export const getDaysInMonth = date => {
  const startWeek = startOfWeek(startOfMonth(date));
  const endWeek = endOfWeek(endOfMonth(date));
  const days = [];
  for (let curr = startWeek; isBefore(curr, endWeek); ) {
    days.push(curr);
    curr = addDays(curr, 1);
  }
  return days;
};

export const isStartOfRange = ({ startDate }, day) => startDate != null && isSameDay(day, startDate);

export const isEndOfRange = ({ endDate }, day) => endDate != null && isSameDay(day, endDate);

export const inDateRange = ({ startDate, endDate }, day) => {
  if (startDate == null || endDate == null) {
    return false;
  }
  const withinRange = isWithinInterval(day, { start: startDate, end: endDate });
  const isStartDate = isSameDay(day, startDate);
  const isEndDate = isSameDay(day, endDate);
  return withinRange || isStartDate || isEndDate;
};

export const isRangeSameDay = ({ startDate, endDate }) => {
  if (startDate != null && endDate != null) {
    return isSameDay(startDate, endDate);
  }
  return false;
};

export const parseOptionalDate = (date, defaultValue) => {
  if (date) {
    const parsedDate = toDate(date);
    if (isValid(parsedDate)) {
      return parsedDate;
    }
    const isoParsedDate = parseISO(date);
    if (isValid(isoParsedDate)) {
      return isoParsedDate;
    }
  }
  return defaultValue;
};

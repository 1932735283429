import { Button, FormHelperText, OutlinedInput, Stack, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EmailSent from './EmailSent';
import validationSchema from './providers/validationSchema';
import { sendEmailToRecoverPassword } from './services';

const RecoverPassword = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [sending, setSending] = useState(false);
  const enableLoading = () => setSending(true);
  const disableLoading = () => setSending(false);
  const changeSentEmailStatus = () => setEmailSent(true);
  const getErrorMessageByStatusCode = statusCode => {
    const clientError = statusCode < 500;
    return clientError ? 'Endereço de e-mail inválido' : 'Ocorreu um erro. Tente novamente';
  };
  const form = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async ({ email }) => {
      try {
        enableLoading();
        await sendEmailToRecoverPassword(email);
        changeSentEmailStatus();
      } catch (err) {
        const message = getErrorMessageByStatusCode(err.response.status);
        form.setErrors({ email: message });
      } finally {
        disableLoading();
      }
    }
  });
  const emailIsInvalid = form.touched.email && form.errors.email;
  if (emailSent) {
    return <EmailSent enteredEmail={form.values.email} />;
  }
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 65px)', width: '100%' }}>
      <Stack
        alignItems="center"
        sx={{
          borderColor: { xs: 'none', md: 'neutral.25' },
          borderRadius: 1,
          borderStyle: 'solid',
          borderWidth: { xs: 0, md: '1px' },
          minHeight: { xs: 'auto', md: 385 },
          px: { xs: 4, md: 4 },
          py: { xs: 0, md: 5 },
          width: { xs: '100%', md: 468 }
        }}
      >
        <Typography data-testid="recoverPasswordTitle" sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>
          Recuperação de senha
        </Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 16, mt: { xs: 2, md: 4 }, textAlign: 'center' }}>
          Enviaremos um link para o seu e-mail que permitirá a redefinição da sua senha.
        </Typography>
        <form onSubmit={form.handleSubmit} style={{ width: '100%' }}>
          <OutlinedInput
            data-testid="passwordResetEmailInput"
            name="email"
            value={form.values.email}
            placeholder="Endereço de e-mail"
            sx={{
              height: 37,
              mt: 4,
              width: '100%'
            }}
            onChange={form.handleChange}
          />
          <FormHelperText data-testid="passwordResetEmailError" variant="error" sx={{ height: 18, mt: 2 }}>
            {emailIsInvalid ? form.errors.email : ''}
          </FormHelperText>
          <LoadingButton data-testid="sendEmailPasswordResetBTN" loading={sending} type="submit" sx={{ mt: 5, width: '100%' }}>
            Enviar
          </LoadingButton>
          <Link to="/auth/login" style={{ marginTop: 3 }}>
            <Button data-testid="passwordResetBackBTN" variant="text" size="large" sx={{ width: '100%' }}>
              Voltar
            </Button>
          </Link>
        </form>
      </Stack>
    </Stack>
  );
};

export default RecoverPassword;

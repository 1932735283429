import axios from 'axios';
import uploadFileTypes from './uploadFile.types';
export const setUploadFile = data => {
  return {
    type: uploadFileTypes.SET_UPLOAD_FILE,
    payload: data
  };
};
export const setUploadProgress = (id, progress, file) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: { id, progress, file }
});
export const successUploadFile = id => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: id
});
export const failureUploadFile = id => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  payload: id
});

export const uploadFileSpalla = (videoId, file) => async dispatch => {
  if (process.env.REACT_APP_UPLOAD_HOST.indexOf('upload.spalla.io') !== -1) {
    console.info('Upload normal');
    return dispatch(uploadFileNormal(videoId, file));
  } else {
    console.info('Upload chunked');
    return dispatch(uploadFileChunked(videoId, file));
  }
};

export const uploadFileChunked = (videoId, file) => async dispatch => {
  const uploadUrl = `${process.env.REACT_APP_UPLOAD_HOST}/v1/videos/${videoId}/source`;
  let tamanhoDoChunk = 1024 * 1024 * 16; // 16MB
  const tamanhoDoArquivo = file.size;
  const nomeDoArquivo = file.name;
  const tentativasTotal = 5;

  switch (tamanhoDoArquivo) {
    case tamanhoDoArquivo < 1024 * 1024 * 256: // arquivo com até 256MB
      // usa o tamanhoDoChunk inicial
      break;
    case tamanhoDoArquivo < 1024 * 1024 * 512: // arquivo com até 512MB
      tamanhoDoChunk = 1024 * 1024 * 32; // chunk de 32MB
      break;
    case tamanhoDoArquivo < 1024 * 1024 * 1024: // arquivo com até 1GB
      tamanhoDoChunk = 1024 * 1024 * 64; // chunk de 64MB
      break;
    case tamanhoDoArquivo >= 1024 * 1024 * 1024: // arquivo maior ou igual que 1GB
      tamanhoDoChunk = 1024 * 1024 * 128; // chunk de 128MB
      break;
    default:
    // usa o tamanhoDoChunk inicial
  }

  let inicio = 0;
  let offset = 0;

  dispatch(setUploadProgress(videoId, 0, file));

  loopPrincipal: while (offset < tamanhoDoArquivo) {
    offset = inicio + tamanhoDoChunk;
    const chunk = file.slice(inicio, offset);
    let fim = offset < tamanhoDoArquivo ? offset - 1 : tamanhoDoArquivo;
    const range = `bytes ${inicio}-${fim}/${tamanhoDoArquivo}`;
    inicio = offset;
    const formPayload = new FormData();
    formPayload.append('file', chunk, nomeDoArquivo);

    let tentativa = 0;
    while (true) {
      tentativa++;

      try {
        const res = await axios({
          url: uploadUrl,
          method: 'post',
          data: formPayload,
          headers: {
            'Content-Range': range
          }
        });

        if (res.status === 201) {
          const percentageProgress = Math.floor((fim / tamanhoDoArquivo) * 100);
          dispatch(setUploadProgress(videoId, percentageProgress, file));
          break; // deu certo, sai do loop de tentativas e tenta o próximo chunk
        } else if (res.status === 202) {
          dispatch(setUploadProgress(videoId, 100, file));
          dispatch(successUploadFile(videoId));
          return true; // tudo certo, upload concluído. sai do loopPrincipal
        } else if (res.status >= 500) {
          console.error('Erro no servidor para', videoId, 'Tentativa ', tentativa, res);
          continue; // tenta novamente o mesmo chunk
        } else {
          console.error('Erro no upload para', videoId, 'Tentativa ', tentativa, res);
          dispatch(failureUploadFile(videoId));
          break loopPrincipal; // erro na requisição (status 4XX), sai do loopPrincipal
        }
      } catch (err) {
        console.error('Erro no upload para', videoId, 'Tentativa ', tentativa, err);
      }

      if (tentativa >= tentativasTotal) {
        // deu ruim, tentou várias vezes e não conseguiu
        dispatch(failureUploadFile(videoId));
        break loopPrincipal; // sai do loopPrincipal
      }
    }
  }

  dispatch(setUploadProgress(videoId, 0, file));
  return false;
};

export const uploadFileNormal = (videoId, file) => async dispatch => {
  const uploadUrl = `${process.env.REACT_APP_UPLOAD_HOST}/v1/videos/${videoId}/source`;
  const tentativasTotal = 5;

  const nomeDoArquivo = file.name;
  const formPayload = new FormData();
  formPayload.append('file', file, nomeDoArquivo);

  dispatch(setUploadProgress(videoId, 0, file));

  let tentativa = 0;
  while (true) {
    tentativa++;

    try {
      const res = await axios({
        url: uploadUrl,
        method: 'post',
        data: formPayload,
        onUploadProgress: progressEvent => {
          const percentageProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
          dispatch(setUploadProgress(videoId, percentageProgress, file));
        }
      });

      if (res.status === 202) {
        dispatch(setUploadProgress(videoId, 100, file));
        dispatch(successUploadFile(videoId));
        return true; // tudo certo, upload concluído. sai do loopPrincipal
      } else if (res.status >= 500) {
        console.error('Erro no servidor para', videoId, 'Tentativa ', tentativa, res);
        continue; // tenta novamente o mesmo chunk
      } else {
        console.error('Erro no upload para', videoId, 'Tentativa ', tentativa, res);
        dispatch(failureUploadFile(videoId));
        break; // erro na requisição (status 4XX), sai do loopPrincipal
      }
    } catch (err) {
      console.error('Erro no upload para', videoId, 'Tentativa ', tentativa, err);
    }

    if (tentativa >= tentativasTotal) {
      // deu ruim, tentou várias vezes e não conseguiu
      dispatch(failureUploadFile(videoId));
      break; // sai do loopPrincipal
    }
  }

  dispatch(setUploadProgress(videoId, 0, file));
  return false;
};

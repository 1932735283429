import { ArrowRightAlt } from '@material-ui/icons';
import { differenceInCalendarMonths } from 'date-fns';
import DefinedRanges from './DefinedRanges';
import Month from './Month';
import { MARKERS } from '..';
import React from 'react';
import { Divider, Grid, Paper, Typography, useTheme } from '@material-ui/core';
import formatDate from '../utils/formatDate';

const Menu = props => {
  const { ranges, dateRange, minDate, maxDate, firstMonth, setFirstMonth, secondMonth, setSecondMonth, setDateRange, helpers, handlers } = props;
  const { startDate, endDate } = dateRange;
  const canNavigateCloser = differenceInCalendarMonths(secondMonth, firstMonth) >= 2;
  const commonProps = { dateRange, minDate, maxDate, helpers, handlers };
  const { palette } = useTheme();
  const startDateLabel = startDate != null ? formatDate(startDate, 'dd MMM yy') : 'Data inicial';
  const endDateLabel = endDate != null ? formatDate(endDate, 'dd MMM yy') : 'Data final';
  return (
    <Paper elevation={5} square>
      <Grid container direction="row" wrap="nowrap">
        <Grid>
          <Grid container sx={{ px: 10, py: 4 }} alignItems="center">
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="subtitle1">{startDateLabel}</Typography>
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <ArrowRightAlt color="action" />
            </Grid>
            <Grid item sx={{ flex: 1, textAlign: 'center' }}>
              <Typography variant="subtitle1">{endDateLabel}</Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container direction="row" justifyContent="center" wrap="nowrap">
            <Month value={firstMonth} setValue={setFirstMonth} navState={[true, canNavigateCloser]} marker={MARKERS.FIRST_MONTH} {...commonProps} />
            <div style={{ borderLeft: `1px solid ${palette.action.hover}` }} />
            <Month
              value={secondMonth}
              setValue={setSecondMonth}
              navState={[canNavigateCloser, true]}
              marker={MARKERS.SECOND_MONTH}
              {...commonProps}
            />
          </Grid>
        </Grid>
        <div style={{ marginBottom: 4 }} />
        <Grid>
          <DefinedRanges selectedRange={dateRange} ranges={ranges} setRange={setDateRange} />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Menu;

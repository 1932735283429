import { Box, Button, CircularProgress, Divider, Modal, Stack, Typography } from '@material-ui/core';
import { CloseRounded as CloseRoundedIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ZoomAccountRow from './ZoomAccountRow';
import { useQuery } from 'react-query';
import { getStatusToken } from '../services';

const AuthWithZoom = forwardRef(({ isMultiAccounts, buttonConfirmTitle, color = 'primary', description, loading, title, onConfirm }, ref) => {
  const { data: zoomAccounts, isLoading: isLoadingAccounts, refetch } = useQuery('getZoomStatusToken', getStatusToken, {
    retry: false,
    enabled: !isMultiAccounts
  });
  const onConfirmLoginModal = async () => {
    await onConfirm();
    await refetch();
  };
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const openModal = () => setOpen(true);
  useImperativeHandle(
    ref,
    () => ({
      openModal,
      closeModal
    }),
    []
  );
  const isDeAuth = buttonConfirmTitle === 'Desfazer integração';
  if (isLoadingAccounts) return <CircularProgress />;
  return (
    <Modal open={open} onClose={closeModal}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderRadius: 1,
          bottom: { xs: 0, sm: 'auto' },
          boxShadow: 24,
          left: { xs: 0, sm: '50%' },
          p: 4,
          position: 'absolute',
          top: { xs: 'auto', sm: '50%' },
          transform: { xs: 'none', sm: 'translate(-50%, -50%)' },
          width: { xs: '100%', sm: 500 }
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ color: 'neutral.dark', fontSize: 16 }}>{title}</Typography>
          <CloseRoundedIcon sx={{ color: 'muted', cursor: 'pointer' }} onClick={closeModal} />
        </Stack>
        {isMultiAccounts && <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />}
        <Typography sx={{ color: 'neutral.body', fontSize: 14, mb: 3 }}>{description}</Typography>
        {!isMultiAccounts && zoomAccounts && !isDeAuth && (
          <>
            <Typography sx={{ color: 'muted', fontSize: 12 }}>CONTAS INTEGRADAS</Typography>
            <Stack gap={3} sx={{ mb: 5, mt: 3, maxHeight: 144, overflowY: 'auto' }}>
              {zoomAccounts.map(account => !account.deauthorized && <ZoomAccountRow account={account} />)}
            </Stack>
          </>
        )}
        {isMultiAccounts && <Divider sx={{ bgcolor: 'grey.200', my: 3 }} />}
        <Stack gap={2} sx={{ mt: 3 }}>
          <LoadingButton disabled={zoomAccounts?.length === 20} color={color} loading={loading} onClick={onConfirmLoginModal}>
            {buttonConfirmTitle}
          </LoadingButton>
          <Button sx={{ bgcolor: 'neutral.light', color: 'neutral.50', '&:hover': { bgcolor: 'neutral.light' } }} onClick={closeModal}>
            Cancelar
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
});

export default AuthWithZoom;

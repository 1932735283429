import React, { createContext, useState } from 'react';

import ConfirmationDialog from './ConfirmationDialog';

export const ConfirmationDialogContext = createContext({});

export const ConfirmationDialogProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogConfig, setDialogConfig] = useState({});

  const openDialog = ({ title, message, cancelButtonProps, confirmButtonProps, actionCallback }) => {
    setDialogOpen(true);
    setDialogConfig({ title, message, actionCallback, cancelButtonProps, confirmButtonProps });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setDialogConfig({});
  };

  const onConfirm = () => {
    resetDialog();
    dialogConfig.actionCallback(true);
  };

  const onDismiss = () => {
    resetDialog();
    dialogConfig.actionCallback(false);
  };

  return (
    <ConfirmationDialogContext.Provider value={{ openDialog }}>
      <ConfirmationDialog
        open={dialogOpen}
        title={dialogConfig.title}
        message={dialogConfig.message}
        cancelButtonProps={dialogConfig.cancelButtonProps}
        confirmButtonProps={dialogConfig.confirmButtonProps}
        onConfirm={onConfirm}
        onDismiss={onDismiss}
      />
      {children}
    </ConfirmationDialogContext.Provider>
  );
};

import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { getMonth, getYear, setMonth, setYear } from 'date-fns';
import React, { useMemo } from 'react';
import generateYears from '../utils/generateYears';
import { Grid, IconButton, MenuItem, Select } from '@material-ui/core';
import { DAYS_COUNT, MONTHS } from '../../../../../../../utils/calendarInfo';

const Header = ({ date, setDate, nextDisabled, prevDisabled, onClickNext, onClickPrevious }) => {
  const handleMonthChange = event => {
    setDate(setMonth(date, parseInt(event.target.value)));
  };
  const handleYearChange = event => {
    setDate(setYear(date, parseInt(event.target.value)));
  };
  const month = getMonth(date);
  const year = getYear(date);
  const yearsList = useMemo(
    () =>
      generateYears(date, DAYS_COUNT).map(year => (
        <MenuItem key={year} value={year}>
          {year}
        </MenuItem>
      )),
    [date]
  );
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{ padding: 1 }}>
        <IconButton
          sx={{
            padding: 2,
            '&:hover': {
              background: 'none'
            }
          }}
          disabled={prevDisabled}
          onClick={onClickPrevious}
        >
          <ChevronLeft color={prevDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
      <Grid item>
        <Select value={month} MenuProps={{ disablePortal: true }} sx={{ border: 'none', fieldset: { display: 'none' } }} onChange={handleMonthChange}>
          {MONTHS.map((month, index) => (
            <MenuItem key={month} value={index}>
              {month}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select value={year} MenuProps={{ disablePortal: true }} sx={{ border: 'none', fieldset: { display: 'none' } }} onChange={handleYearChange}>
          {yearsList}
        </Select>
      </Grid>
      <Grid item sx={{ padding: 1 }}>
        <IconButton
          sx={{
            padding: 2,
            '&:hover': {
              background: 'none'
            }
          }}
          disabled={nextDisabled}
          onClick={onClickNext}
        >
          <ChevronRight color={nextDisabled ? 'disabled' : 'action'} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Header;

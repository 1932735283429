import { Box, Divider, Stack, Typography } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { useQuery } from 'react-query';
import Form from './components/Form';
import SkeletonItem from '../components/SkeletonItem';
import { Context } from '../Context';
import { getRoomInfo } from '../services';

const Register = () => {
  const { liveId, setArenaUser } = useContext(Context);
  const { data, isLoading } = useQuery(['room'], () => getRoomInfo(liveId));
  useEffect(() => {
    setArenaUser(null);
    localStorage.removeItem('arenaUser');
  }, [setArenaUser]);
  return (
    <Box
      sx={{
        backgroundImage: data?.background_login ? `url(${data.background_login})` : 'url(/media/cogna/default-background.jpg)',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: '100vh',
        position: 'relative',
        width: '100vw'
      }}
    >
      <Box sx={{ backgroundColor: '#000', height: '100%', left: 0, opacity: '50%', position: 'absolute', top: 0, width: '100%' }} />
      <Stack
        alignItems="center"
        direction={{ xs: 'column', lg: 'row' }}
        justifyContent={{ xs: 'center', lg: 'space-between' }}
        gap={{ xs: 5, lg: 0 }}
        sx={{ height: '100%', px: { xs: 5, md: '100px', lg: '136px' } }}
      >
        <Box sx={{ zIndex: 1 }}>
          <Typography
            sx={{
              color: 'common.white',
              fontSize: { xs: 20, lg: '2vw' },
              fontWeight: 600,
              maxWidth: { xs: 238, lg: '100%' },
              textAlign: { xs: 'center', lg: 'start' }
            }}
          >
            {isLoading ? <SkeletonItem height={36} width={{ xs: 230, lg: 420 }} /> : data.schedule_date}
          </Typography>
          <Divider sx={{ bgcolor: 'neutral.25', height: '1px', my: 2 }} />
          <Typography
            sx={{
              color: 'neutral.light',
              fontSize: { xs: 16, lg: '1.25vw' },
              fontWeight: 400,
              maxWidth: { xs: 230, lg: 352 },
              textAlign: { xs: 'center', lg: 'start' }
            }}
          >
            {isLoading ? <SkeletonItem height={36} width={{ xs: 230, lg: 420 }} /> : data.description}
          </Typography>
        </Box>
        <Form />
      </Stack>
    </Box>
  );
};

export default Register;

import { CircularProgress, LinearProgress, Stack, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { getTranscodeProgress } from '../../services/transcodeProgress';
import Context from '../../../_metronic/layout/components/aside/Context';
import ProgressModal from './ProgressModal';
import isValidUUID from '../../utils/isValidUUID';
import { getProgressType } from './utils';

const SideMenuProgress = () => {
  const uploadProgressItens = useSelector(state => state.uploadFile.fileProgress);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [progressHover, setProgressHover] = useState(false);
  const { menuHover, setMenuHover } = useContext(Context);
  const { isLoading, data: itensWithTranscodeProgress, isError } = useQuery(['transcode'], getTranscodeProgress, { refetchInterval: 5000 });
  if (isLoading)
    return (
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <CircularProgress />
      </Stack>
    );
  if (isLoading) return <CircularProgress />;
  const getValidKeys = Object.keys(uploadProgressItens).filter(isValidUUID);
  const uploadItemsInArray = getValidKeys.map(currentKey => uploadProgressItens[currentKey]);
  const uploadItems = uploadItemsInArray.map(item => getProgressType(item, 'upload'));
  const itemsWithProgress = uploadItems.concat(itensWithTranscodeProgress);
  const hasVideos = isError ? false : itemsWithProgress.length > 0;
  const closeModalHandler = () => {
    setMenuHover(false);
    setModalIsOpen(false);
  };
  const countCompletedVideos = itens => itens.reduce((count, video) => count + (video.progress === 100), 0);
  const calculateProgressPercentage = (videosCount, completedVideosCount) => (completedVideosCount ? (completedVideosCount / videosCount) * 100 : 0);
  const videosCount = isError ? 0 : itemsWithProgress.length;
  const completedVideosCount = countCompletedVideos(itemsWithProgress);
  const progressInPercentage = calculateProgressPercentage(videosCount, completedVideosCount).toFixed(0);
  const textColors = progressHover ? 'primary.main' : 'neutral.body';
  return (
    <>
      {hasVideos && (
        <>
          <Stack
            gap={1}
            sx={{ cursor: 'pointer', px: menuHover ? 4 : 2, py: 2, '&:hover': { bgcolor: 'neutral.light', color: 'primary.main' } }}
            onMouseUp={() => setModalIsOpen(true)}
            onClick={() => setModalIsOpen(true)}
            onMouseOver={() => setProgressHover(true)}
            onMouseLeave={() => setProgressHover(false)}
          >
            <Typography sx={{ color: textColors, display: menuHover ? 'block' : 'none', fontSize: 12 }}>Processando vídeos...</Typography>
            <LinearProgress color={progressInPercentage === 100 ? 'success' : 'primary'} variant="determinate" value={progressInPercentage} />
            <Stack alignItems="center" justifyContent="space-between" direction={menuHover ? 'row' : 'row-reverse'}>
              <Typography sx={{ color: textColors, fontSize: 10 }}>{progressInPercentage}%</Typography>
              <Typography sx={{ color: textColors, fontSize: 10 }}>
                {completedVideosCount}/
                <Typography sx={{ color: progressHover ? 'primary.main' : 'muted', display: 'inline-block', fontSize: 10 }}>{videosCount}</Typography>
              </Typography>
            </Stack>
          </Stack>
          <ProgressModal videosProgress={itemsWithProgress} modalIsOpen={modalIsOpen} closeModalHandler={closeModalHandler} />
        </>
      )}
    </>
  );
};

export default SideMenuProgress;

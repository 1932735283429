import { Stack, Switch, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { zoomActivate } from '../services';
import addNotification from '../../../utils/addNotification';

const ZoomAccountRow = ({ account }) => {
  const [active, setActive] = useState(account.activeImport);
  const onZoomSwitch = evt => {
    try {
      const payload = { activeImport: evt.target.checked, zoomAccountId: account.zoomAccountId };
      zoomActivate(payload);
      setActive(evt.target.checked);
    } catch {
      addNotification({ message: 'Falha ao tentar ativar importação.', type: 'danger' });
    }
  };
  return (
    <Stack alignItems="center" direction="row" gap={3}>
      <Switch checked={active} onChange={onZoomSwitch} />
      <Typography sx={{ color: 'neutral.body', fontSize: 16 }}>{account.zoomAccountId}</Typography>
    </Stack>
  );
};

export default ZoomAccountRow;

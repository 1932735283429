const generateYears = (relativeTo, count) => {
  // Determina o ponto médio em relação ao número de anos desejados
  const half = Math.floor(count / 2);

  // Cria um array de anos, centrado em torno do ano especificado
  return Array(count)
    .fill(0)
    .map((_, i) => relativeTo.getFullYear() - half + i);
};
export default generateYears;

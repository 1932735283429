import { Box } from '@material-ui/core';
import React, { useContext, useEffect } from 'react';
import { Context } from '../../Context';

const Player = ({ height, playerContainer }) => {
  const { liveId } = useContext(Context);
  useEffect(() => {
    const playerElement = document.querySelector('#spalla-player');
    playerElement.style.display = 'block';
    const containerElement = playerContainer.current;
    containerElement.appendChild(playerElement);
    window.Spalla.config = {
      element: '#spalla-player',
      stream: liveId,
      audioPlayer: 1
    };
    return () => {
      containerElement.innerText = '';
      document.body.appendChild(playerElement);
      playerElement.style.display = 'none';
    };
  }, [liveId, playerContainer]);
  return (
    <Box
      ref={playerContainer}
      sx={{
        borderRadius: { xs: 0, lg: 3 },
        height,
        overflow: 'hidden',
        position: 'relative',
        width: { xs: '100%', lg: '68%' }
      }}
    ></Box>
  );
};

export default Player;

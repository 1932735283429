import * as requestFromServer from './atendimentosCrud';
import { atendimentosSlice, callTypes } from './atendimentosSlice';
const { actions } = atendimentosSlice;

export const fetchLiveInfo = (liveId, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLiveInfo(liveId)
    .then(response => {
      dispatch(actions.liveFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find Eventos with id: " + id;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOldLiveInfo = (liveId, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOldLiveInfo(liveId)
    .then(response => {
      dispatch(actions.oldLiveFetched(response.data));
    })
    .catch(error => {
      error.clientMessage = "Can't find Eventos with id: " + id;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchTelemetryInfo = (liveId, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findTelemetryInfo(liveId, id)
    .then(response => {
      dispatch(actions.telemetryFetched(response.data[0]));
    })
    .catch(error => {
      error.clientMessage = "Can't find Eventos with id: " + id;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEventos = (liveId, id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findEventos(liveId, id)
    .then(response => {
      let eventos = response.data;
      if (eventos.length > 0) {
        dispatch(actions.eventosFetched(eventos));
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Eventos with id: " + id;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

//OLD

/*
function compare(a, b) {
  if (a.sequencia < b.sequencia) {
    return -1;
  }
  if (a.sequencia > b.sequencia) {
    return 1;
  }
  return 0;
}

function generateData(data) {
  let result = [];

  for (let index = 0; index < data.length; index++) {
    const element = data[index];
    let category, startTime, endTime;

    switch (element.evento) {
      case "pronto":
        category = "inicialização";

        let firstPlayIndex = data
          .slice(index, -1)
          .findIndex(x => x.evento == "firstplaying");
        console.log(firstPlayIndex);

        index += firstPlayIndex;
        startTime = new Date(element.ts).getTime();
        endTime = new Date(data[index].ts).getTime();
        result.push({
          category,
          startTime,
          endTime
        });

        break;
      case "seeking":
        category = "seeking";

        let seekedIndex = data.slice(index, -1).find(x => x.evento == "seeked");
        console.log(seekedIndex);
        break;
        index += seekedIndex;
        startTime = new Date(element.ts).getTime();
        endTime = new Date(element.ts).getTime();
        result.push({
          category,
          startTime,
          endTime
        });

        break;

      default:
        console.log("default");
        break;
    }
  }
  console.log(result);
  return result;
}

export const fetchEventos = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findEventos(id)
    .then(response => {
      let eventos = response.data;
      if (eventos.length > 0) {
        let buckets = eventos.reduce(function(buckets, item) {
          if (!buckets[item.view_id]) buckets[item.view_id] = [];
          buckets[item.view_id].push(item);
          return buckets;
        }, {});
        let res = [];

        Object.keys(buckets).forEach(key => {
          buckets[key].sort(compare);

          res.push(buckets[key]);

          //
        });

        res.forEach((elem, i) => {
          res[i] = generateData(elem);
        });

        console.log(res);
      }
    })
    .catch(error => {
      error.clientMessage = "Can't find Eventos with id: " + id;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
}; */

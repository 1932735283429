import { Box, CardContent, Divider, IconButton, Modal, Stack, Typography } from '@material-ui/core';
import { CloseRounded as CloseIcon } from '@material-ui/icons';
import React from 'react';

const LogModal = ({ children, data, open, setOpen }) => {
  const modalLabels = [
    {
      title: 'Identificação',
      labels: [`${data.identifier}`]
    },
    {
      title: 'Responsável',
      labels: [`Nome: ${data.user.name}`, `E-mail: ${data.user.email}`, `IP: ${data.ip}`, `Navegador: ${data.user_agent}`]
    }
  ];
  return (
    <>
      {children}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            borderRadius: 1,
            boxShadow: 24,
            bgcolor: 'background.paper',
            left: '50%',
            outline: 'none',
            position: 'absolute',
            py: 3,
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: 498
          }}
        >
          <Stack alignItems="center" direction="row" justifyContent="space-between" sx={{ px: 4 }}>
            <Stack>
              <Typography
                sx={{
                  color: 'secondary.main',
                  fontWeight: 400,
                  fontSize: 14
                }}
              >
                Informações
              </Typography>
            </Stack>
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon sx={{ color: 'muted', cursor: 'pointer' }} />
            </IconButton>
          </Stack>
          {modalLabels.map(currentLabel => (
            <Stack key={currentLabel.id}>
              <Divider sx={{ bgcolor: 'grey.200' }} />
              <CardContent sx={{ py: 3 }}>
                <Typography
                  sx={{
                    color: 'neutral.body',
                    fontSize: 14,
                    fontWeight: 600
                  }}
                >
                  {currentLabel.title}
                </Typography>
                {currentLabel.labels.map(label => (
                  <Typography
                    key={label}
                    sx={{
                      color: 'neutral.body',
                      fontSize: 14
                    }}
                  >
                    {label}
                  </Typography>
                ))}
              </CardContent>
            </Stack>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default LogModal;

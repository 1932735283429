import React from 'react';
import clsx from 'clsx';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { checkIsActive, KTSVG } from '@metronic/_helpers';
import { Box, Chip, Stack } from '@material-ui/core';

const AsideMenuItem = ({ active, children, to, title, icon, Link = ReactRouterLink, linkProperty = 'to', hasBullet = false, beta, ...props }) => {
  const { pathname } = useLocation();
  const isActive = active || checkIsActive(pathname, to);

  const Component = to ? Link : Box;
  return (
    <li
      className={clsx('menu-item menu-item-submenu', {
        'menu-item-open': isActive
      })}
      aria-haspopup="true"
      data-menu-toggle="hover"
      {...props}
    >
      <Component className="menu-link menu-toggle" title={title} {...{ [linkProperty]: to }}>
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot" />
          </span>
        )}
        {icon && <KTSVG path={icon} className="svg-icon menu-icon" />}
        <span className="menu-text">{title}</span>
        {beta && (
          <Stack alignItems="center" justifyContent="center">
            <Chip label="BETA" color="info" sx={{ ml: 3 }} />
          </Stack>
        )}
      </Component>
      {children}
    </li>
  );
};

export { AsideMenuItem };

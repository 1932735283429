import { Card, CardContent, Grid, IconButton, Stack, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import VerticalSeparator from '@app/components/VerticalSeparator';

const Toolbar = ({ children, goBackLink, title, titleProps, titleWidth = 300, sx }) => {
  const lastLocation = useLastLocation();
  const history = useHistory();
  const goBackClickHandler = () => {
    const isFunction = typeof goBackLink === 'function';
    if (isFunction) {
      return goBackLink();
    }
    const canGoBack = !!lastLocation && !goBackLink;
    if (canGoBack) {
      return history.goBack();
    }
    history.push(goBackLink);
  };
  const titleType = typeof title;
  const titleTypeIsString = titleType === 'string';
  return (
    <Card sx={{ height: 69, ...sx }}>
      <CardContent sx={{ p: 3 }}>
        <Grid container alignItems="center" sx={{ height: '100%' }}>
          <Grid item xs>
            <Stack direction="row" alignItems="center" spacing={3} sx={{ height: '100%' }}>
              <IconButton onClick={goBackClickHandler}>
                <ArrowBackIcon />
              </IconButton>
              <VerticalSeparator />
              {titleTypeIsString ? (
                <Typography
                  fontWeight={500}
                  {...titleProps}
                  sx={{
                    fontSize: { xs: 14, lg: 16 },
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: titleWidth,
                    whiteSpace: 'nowrap',
                    ...titleProps?.sx
                  }}
                >
                  {title}
                </Typography>
              ) : (
                title
              )}
            </Stack>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Toolbar;

import { Avatar, Stack, Typography } from '@material-ui/core';
import { Event as EventIcon, Group as GroupIcon, Home as HomeIcon, Logout as LogoutIcon } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../Context';

const Option = ({ icon: Icon, label, sx }) => (
  <Stack alignItems="center" direction="row" gap={2} sx={{ cursor: 'pointer', display: { xs: 'none', lg: 'flex' }, ...sx }}>
    <Icon sx={{ color: '#F3F6F9' }} />
    <Typography sx={{ color: '#F3F6F9', fontWeight: 600 }}>{label}</Typography>
  </Stack>
);

const Header = ({ infos, isLoading }) => {
  const history = useHistory();
  const { liveId, setArenaUser } = useContext(Context);
  const logoutHandler = () => {
    localStorage.removeItem('arenaUser');
    history.push(`/cogna/register?liveId=${liveId}`);
    setArenaUser(null);
  };
  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={5}
      sx={{ bgcolor: 'neutral.main', height: 72, mb: { xs: 0, lg: 5 }, px: { xs: 4, lg: 5 }, width: '100%' }}
    >
      <Avatar
        src={infos?.schedule_image.includes('placeholder.png') || isLoading ? '/media/cogna/default-logo.png' : infos.schedule_image}
        sx={{ height: 40, width: 40 }}
      />
      <Stack alignItems="center" direction="row" gap={4}>
        <Option icon={HomeIcon} label="INÍCIO" />
        <Option icon={GroupIcon} label="ORADORES" />
        <Option icon={EventIcon} label="AGENDA" />
      </Stack>
      <LogoutIcon sx={{ color: '#F3F6F9', cursor: 'pointer', marginLeft: 'auto' }} onClick={logoutHandler} />
    </Stack>
  );
};

export default Header;

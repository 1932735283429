import { Box, Stack } from '@material-ui/core';
import React, { useEffect } from 'react';
import './index.css';

const Chat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://go.arena.im/public/js/arenachatlib.js?p=taghos&e=taghos-global';
    script.async = true;

    setTimeout(() => {
      document.body.appendChild(script);
    }, 400);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <Box
      sx={{
        backgroundImage: { xs: 'url(/media/cogna/background-mobile.png)', lg: 'url(/media/cogna/background-desktop.png)' },
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        height: 'auto',
        minHeight: '100vh',
        maxWidth: '100%',
        position: 'relative',
        width: '100vw'
      }}
    >
      <Box sx={{ backgroundColor: '#000', height: '100%', left: 0, opacity: '50%', position: 'absolute', top: 0, width: '100%' }} />
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%', width: '100%' }}>
        <Box sx={{ height: '480px', width: 400 }}>
          <div class="arena-chat" data-publisher="taghos" data-chatroom="taghos-global" data-position="in-page"></div>
        </Box>
      </Stack>
    </Box>
  );
};

export default Chat;

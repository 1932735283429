import { StatusCodes } from 'http-status-codes';
import { get } from 'lodash';
import { store as notificationStore } from 'react-notifications-component';

import notification from '@app/providers/notification';

export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    err => Promise.reject(err)
  );

  axios.interceptors.response.use(
    config => config,
    error => {
      const statusCode = get(error, 'response.status');
      const isUserUnauthorized = statusCode === StatusCodes.UNAUTHORIZED;

      const pathAuthLogin = '/auth/login';
      const fullRoute = window.location.pathname;
      const routeWithoutDomain = fullRoute.replace(/^.*\/\/[^/]+/, '');
      const pageIsLogin = routeWithoutDomain === pathAuthLogin;

      if (isUserUnauthorized) {
        localStorage.removeItem('persist:v706-demo1-auth');
        if (!pageIsLogin) {
          window.location.replace(pathAuthLogin);
        }
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    config => config,
    error => {
      const statusCode = get(error, 'response.status', StatusCodes.SERVICE_UNAVAILABLE);

      const isServerError = /^5\d{2}$/.test(statusCode);
      const isWhereByRequest = error.config.url.includes('whereby/meeting');

      if (isServerError && !isWhereByRequest) {
        notificationStore.addNotification({
          ...notification,
          title: 'Ops',
          message: 'Aconteceu um erro interno...',
          type: 'danger'
        });
      }

      return Promise.reject(error);
    }
  );
}

import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email()
      .required()
  });

export default validationSchema;

import { Card, CardContent, Stack, Typography } from '@material-ui/core';
import React from 'react';

const EmptyState = ({ icon: Icon, title, description, children, sx, ...props }) => (
  <Card {...props} sx={{ height: 391, ...sx }}>
    <CardContent sx={{ height: '100%' }}>
      <Stack alignItems="center" justifyContent="center" sx={{ height: '100%' }}>
        <Icon sx={{ color: 'muted', height: 45, mb: 3, width: 65 }} />
        <Typography sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600, mb: 2 }}>{title}</Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 14, mb: 4, textAlign: 'center', width: 352 }}>{description}</Typography>
        {children}
      </Stack>
    </CardContent>
  </Card>
);

export default EmptyState;

import { useContext } from 'react';

import { ConfirmationDialogContext } from './Provider';

export const useConfirmationDialog = () => {
  const { openDialog } = useContext(ConfirmationDialogContext);

  const getConfirmation = ({ ...options }) =>
    new Promise(res => openDialog({ actionCallback: res, ...options }));

  return getConfirmation;
};

import { Button, Stack, Typography } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, MarkEmailRead as MarkEmailReadIcon } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

const EmailSent = ({ enteredEmail }) => {
  const maskEmail = email => {
    const [name, domain] = email.split('@');
    const maskedName = maskName(name);
    return `${maskedName}@${domain}`;
  };
  const maskName = name => {
    const numberOfCharacters = name.length;
    const firstCharacter = name.charAt(0);
    const lastCharacter = name.charAt(numberOfCharacters - 1);
    const maskedCharacters = maskCharacters(numberOfCharacters - 2);
    return `${firstCharacter}${maskedCharacters}${lastCharacter}`;
  };
  const maskCharacters = count => '*'.repeat(count);
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ height: 'calc(100vh - 65px)', width: '100%' }}>
      <Stack
        alignItems="center"
        sx={{
          borderRadius: 1,
          minHeight: { xs: 'auto', md: 385 },
          px: { xs: 4, md: 4 },
          py: { xs: 0, md: 5 },
          width: { xs: '100%', md: 468 }
        }}
      >
        <MarkEmailReadIcon color="primary" sx={{ height: 60, mb: 3, width: 60 }} />
        <Typography data-testid="emailSendConfirm" sx={{ color: 'neutral.body', fontSize: 20, fontWeight: 600 }}>
          E-mail enviado
        </Typography>
        <Typography sx={{ color: 'neutral.50', fontSize: 16, mt: { xs: 2, md: 4 }, textAlign: 'center' }}>
          Enviamos um e-mail para o endereço {maskEmail(enteredEmail)} com um link para você alterar a senha da sua conta.
        </Typography>
        <Link to="/auth/login" style={{ marginTop: 5, width: '100%' }}>
          <Button startIcon={<ArrowBackIcon />} size="large" sx={{ width: '100%' }}>
            Voltar para o início
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};

export default EmailSent;

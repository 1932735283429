import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Integrations from './pages/Integrations';

const IntegrationsModule = () => (
  <Switch>
    <Route from="/integrations" exact component={Integrations} />
  </Switch>
);

export default IntegrationsModule;

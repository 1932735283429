import React, { createContext, useContext, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import ptBR from './messages/pt-br.json';
import en from './messages/en.json';

const messageList = {
  'pt-BR': ptBR,
  en
};

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

const defaultLanguage = 'pt-BR';

export const I18nProvider = ({ children }) => {
  const [locale, setLocale] = useState(() => {
    const previouslyDefinedLanguage = localStorage.getItem('spalla_language');
    return previouslyDefinedLanguage || defaultLanguage;
  });
  const [messages, setMessages] = useState(messageList[locale]);

  useEffect(() => {
    localStorage.setItem('spalla_language', locale);
    setMessages(messageList[locale]);
  }, [locale]);

  console.log('locale', locale);

  return (
    <LanguageContext.Provider value={{ locale, messages, setLocale }}>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

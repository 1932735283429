import { Box, FormHelperText, OutlinedInput, Typography } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Context } from '../../Context';
import validationSchema from '../providers/validationSchema';
import { registerParticipant } from '../services/participant';

const inputStyles = {
  bgcolor: '#2a2a2a',
  border: 'none',
  height: { xs: 37, lg: '2.89vw' },
  width: '100%'
};

const Form = () => {
  const history = useHistory();
  const { liveId, setArenaUser } = useContext(Context);
  const form = useFormik({
    initialValues: {
      name: '',
      email: ''
    },
    validationSchema,
    onSubmit: async values => {
      const { name, email } = values;
      localStorage.setItem('arenaUser', JSON.stringify({ name, email }));
      registerParticipant({ name, email, liveId, clientId: '6037e963de87a7e5b9412049' });
      setArenaUser({ name, email });
      history.push(`/cogna/classroom?liveId=${liveId}`);
    }
  });
  const hasErrors = fieldName => form.touched[fieldName] && form.errors[fieldName];
  return (
    <Box
      sx={{
        bgcolor: 'neutral.main',
        borderRadius: 3,
        minHeight: { xs: 278, lg: '22.2vw' },
        px: { xs: 4, lg: '1.875vw' },
        py: { xs: 5, lg: '2.5vw' },
        width: { xs: '100%', lg: '36.5vw' },
        zIndex: 1
      }}
    >
      <Typography sx={{ color: 'neutral.light', fontSize: { xs: 20, lg: '1.5vw' }, fontWeight: 600 }}>Registre-se</Typography>
      <Box sx={{ mb: { xs: 5, lg: '2.5vw' }, mt: { xs: 4, lg: '1.875vw' } }}>
        <OutlinedInput name="email" value={form.values.email} placeholder="E-mail" onChange={form.handleChange} sx={inputStyles} />
        <FormHelperText color="error" sx={{ mb: { xs: 3, lg: '1.25vw' } }}>
          {hasErrors('email') && form.errors.email}
        </FormHelperText>
        <OutlinedInput
          name="name"
          value={form.values.name}
          placeholder="Nome completo"
          inputProps={{ maxLength: 50 }}
          onChange={form.handleChange}
          sx={inputStyles}
        />
        <FormHelperText color="error">{hasErrors('name') && form.errors.name}</FormHelperText>
      </Box>
      <LoadingButton
        sx={{
          bgcolor: '#7E3AE7',
          color: 'neutral.light',
          fontSize: { xs: 16, lg: '1.25vw' },
          height: { xs: 44, lg: '3.43vw' },
          width: '100%',
          ':hover': { bgcolor: '#652EB9' }
        }}
        onClick={form.handleSubmit}
      >
        Registrar
      </LoadingButton>
    </Box>
  );
};

export default Form;

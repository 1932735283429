import { Box } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import RecoverPasswordTemplate from './components/RecoverPasswordTemplate';
import Login from './pages/Login';

const AuthModule = () => (
  <Box
    sx={{
      backgroundImage: `url('/media/bg/bg-3.jpg')`,
      backgroundPosition: 'top',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      minHeight: '100vh'
    }}
  >
    <Switch>
      <Route path="/auth/login" exact component={Login} />
      <Route path="/auth/recover-password" component={RecoverPasswordTemplate} />
      <Redirect from="/auth" exact={true} to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  </Box>
);

export default AuthModule;

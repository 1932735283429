import { IconButton, Stack, Typography, useTheme } from '@material-ui/core';
import React from 'react';

const Day = ({
  filled = false,
  isToday = false,
  highlighted = false,
  disabled = false,
  startOfRange = false,
  endOfRange = false,
  value,
  onClick,
  onHover
}) => {
  const { palette } = useTheme();
  const leftBorderRadius = { borderRadius: startOfRange ? '50% 0 0 50%' : '' };
  const rightBorderRadius = { borderRadius: endOfRange ? '0 50% 50% 0' : '' };
  const dayBorderRadius = {
    ...leftBorderRadius,
    ...rightBorderRadius
  };
  const isEnable = !disabled;
  const mustBeFilled = filled;
  return (
    <Stack
      direction="row"
      sx={{
        backgroundColor: isEnable && highlighted ? palette.action.hover : 'none',
        ...dayBorderRadius
      }}
    >
      <IconButton
        sx={{
          backgroundColor: isEnable && mustBeFilled ? palette.primary.main : 'none',
          border: isEnable && isToday ? `1px solid ${palette.primary.main}` : 'none',
          height: 36,
          padding: 0,
          '&:hover': {
            backgroundColor: isEnable && mustBeFilled ? palette.primary.main : 'none'
          },
          width: 36
        }}
        disabled={disabled}
        onClick={onClick}
        onMouseOver={onHover}
      >
        <Typography
          sx={{
            color: isEnable ? palette.common.black : palette.neutral.muted,
            lineHeight: 1.6
          }}
          variant="body2"
        >
          {value}
        </Typography>
      </IconButton>
    </Stack>
  );
};

export default Day;

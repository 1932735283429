import EmptyState from '@app/components/EmptyState';
import {
  Search as SearchIcon,
  StickyNote2 as StickyNote2Icon,
  SearchOff as SearchOffIcon,
  ArrowForward as ArrowForwardIcon,
  ArrowBack as ArrowBackIcon
} from '@material-ui/icons';
import {
  Card,
  CardContent,
  Divider,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
  PaginationItem
} from '@material-ui/core';
import React, { useState } from 'react';
import RecordsRow from './components/RecordsRow';
import { useQuery } from 'react-query';
import { getEventTypes, getLogs } from '../../services';
import FilterButton from './components/FilterButton';
import { startOfYear } from 'date-fns';
import RecordsRowSkeletons from './components/RecordsRowSkeletons';
import RecordsHeader from './components/RecordsHeader';

const ListRecords = () => {
  const [page, setPage] = useState(1);
  const [sortDirection, setDirection] = useState('desc');
  const [dateRange, setDateRange] = useState({
    startDate: startOfYear(new Date()),
    endDate: new Date()
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(930));
  const [accountId, setAccountId] = useState('');
  const [clientId, setClientId] = useState('');
  const [teamId, setTeamId] = useState('');
  const [limit, setLimit] = useState(10);
  const [searchType, setSearchType] = useState('user');
  const [term, setTerm] = useState('');
  const [search, setSearch] = useState('');
  const searchQuery = searchType === 'user' ? `user=${search}` : `identifier=${search}`;
  const { isLoading: isLoadingLogs, data, isError: logsError } = useQuery(
    [page, limit, search, accountId, dateRange.startDate, dateRange.endDate, sortDirection],
    () => getLogs(page, limit, searchQuery, accountId, dateRange.startDate.toISOString(), dateRange.endDate.toISOString(), sortDirection)
  );
  const { isLoading: isLoadingTypes, data: eventTypes, isError: eventsError } = useQuery('getTypes', getEventTypes);
  const getEventType = type => eventTypes[type];
  const logs = data?.items;
  const clearAccountId = () => setAccountId('');
  const handleChange = (_, value) => {
    if (typeof value === 'number') {
      setPage(value);
    }
  };
  const searchWithEnter = evt => {
    const enterWasPressed = evt.key === 'Enter';
    if (enterWasPressed) {
      setPage(1);
      setSearch(term);
    }
  };
  const idIsNotDefault = id => id !== 'default';
  const changeAccountId = id => {
    const newAccountId = idIsNotDefault(id) ? id : '';
    setAccountId(newAccountId);
  };
  const changeClientId = id => {
    changeAccountId(id);
    setClientId(id);
  };
  const limits = [10, 25, 30, 50];
  const isLoading = isLoadingLogs || isLoadingTypes;
  const hasNoLogs = logs?.length === 0 || !logs;
  const hasNoLogsWithSearch = hasNoLogs && !!search;
  const emptyStateTexts = {
    title: hasNoLogsWithSearch ? 'Sem resultado' : 'Nenhum evento registrado',
    description: hasNoLogsWithSearch
      ? 'Nenhum resultado encontrado, tente novamente com outra palavra-chave.'
      : 'Ações como criar, editar e excluir serão registradas nessa página.',
    icon: hasNoLogsWithSearch ? SearchOffIcon : StickyNote2Icon
  };
  const hasError = logsError || eventsError;
  const changeTeamId = id => {
    let newAccountId = id;
    if (!idIsNotDefault(id)) {
      const hasSelectedClient = idIsNotDefault(teamId);
      newAccountId = hasSelectedClient ? teamId : '';
    }
    setTeamId(newAccountId);
    changeAccountId(newAccountId);
  };
  return (
    <Card sx={{ height: '100%' }}>
      <Stack justifyContent="space-between" sx={{ height: '100%' }}>
        <Stack>
          <CardContent>
            <Stack alignItems={{ xs: 'start', md: 'center' }} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>Registros</Typography>
              <Stack
                alignItems={{ xs: 'end', md: 'center' }}
                direction={{ xs: 'column', md: 'row' }}
                gap={2}
                sx={{ mt: { xs: '-30px', md: 0 }, width: { xs: '100%', md: 'auto' } }}
              >
                <FilterButton
                  clientId={clientId}
                  teamId={teamId}
                  dateRange={dateRange}
                  onChangeTeamId={changeTeamId}
                  setDateRange={setDateRange}
                  onChangeClientId={changeClientId}
                  clearAccountId={clearAccountId}
                  buttonProps={{ sx: { height: 37, mr: 0 } }}
                />
                <Stack alignItems="center" direction="row" sx={{ width: { xs: '100%', sm: 'auto' } }}>
                  <Select
                    value={searchType}
                    sx={{
                      bgcolor: 'secondary.light',
                      borderBottomRightRadius: 0,
                      borderColor: 'neutral.25',
                      borderStyle: 'solid',
                      borderTopRightRadius: 0,
                      borderWidth: '1px 0 1px 1px',
                      fieldset: { display: 'none' },
                      height: 37,
                      width: { xs: 90, lg: 105 }
                    }}
                    onChange={evt => setSearchType(evt.target.value)}
                  >
                    <MenuItem value="identifier">Identificador</MenuItem>
                    <MenuItem value="user">Responsavel</MenuItem>
                  </Select>
                  <OutlinedInput
                    value={term}
                    placeholder="Pesquisar"
                    endAdornment={
                      <InputAdornment
                        position="end"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          setPage(1);
                          setSearch(term);
                        }}
                      >
                        <SearchIcon />
                      </InputAdornment>
                    }
                    sx={{
                      borderBottomLeftRadius: 0,
                      borderColor: 'neutral.25',
                      borderStyle: 'solid',
                      borderTopLeftRadius: 0,
                      borderWidth: '1px 1px 1px 0',
                      fieldset: { display: 'none' },
                      height: 37,
                      width: { xs: '100%', sm: 220, lg: 344 }
                    }}
                    onChange={evt => setTerm(evt.target.value)}
                    onKeyDown={searchWithEnter}
                  />
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
          {(hasNoLogs && !isLoading) || hasError ? (
            <EmptyState
              icon={emptyStateTexts.icon}
              title={emptyStateTexts.title}
              description={emptyStateTexts.description}
              sx={{ my: 'auto', height: '60vh' }}
            />
          ) : (
            <>
              {!isMobile && <RecordsHeader sortDirection={sortDirection} setDirection={setDirection} />}
              <Divider sx={{ bgcolor: 'neutral.25', my: 2 }} />
              {isLoading
                ? [...Array(10)].map(index => (
                    <Stack key={index}>
                      <RecordsRowSkeletons isMobile={isMobile} />
                      <Divider sx={{ bgcolor: 'neutral.25', my: 2 }} />
                    </Stack>
                  ))
                : logs?.map(log => (
                    <Stack key={log.id}>
                      <RecordsRow data={log} getEventType={getEventType} isMobile={isMobile} />
                      <Divider sx={{ bgcolor: 'neutral.25', my: 2 }} />
                    </Stack>
                  ))}
            </>
          )}
        </Stack>
        {!hasNoLogs && !hasError && (
          <CardContent sx={{ alignItems: 'center', display: 'flex' }}>
            <Select
              value={limit}
              sx={{
                bgcolor: 'secondary.light',
                border: 'none',
                fontSize: 12,
                height: 38,
                pr: 3,
                width: 58
              }}
              onChange={evt => setLimit(evt.target.value)}
            >
              {limits.map(limit => (
                <MenuItem key={limit} value={limit}>
                  {limit}
                </MenuItem>
              ))}
            </Select>
            <Pagination
              size={isMobile ? 'small' : 'large'}
              count={isLoading ? 10 : data?.pagination.pagesTotal}
              page={page}
              renderItem={item => {
                return (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                    sx={{ '&:hover': { bgcolor: 'transparent' } }}
                  />
                );
              }}
              onChange={handleChange}
              sx={{ width: '100%' }}
            />
          </CardContent>
        )}
      </Stack>
    </Card>
  );
};

export default ListRecords;

import notificationSettings from '@app/providers/notification';
import { store as notification } from 'react-notifications-component';

const addNotification = options => {
  notification.addNotification({
    ...notificationSettings,
    ...options
  });
};

export default addNotification;

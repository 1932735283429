export const rgbaToHex = rgbaString => {
  const rgba = rgbaString.match(/\d+(\.\d+)?/g).map(Number);
  let [r, g, b, a] = rgba;
  if (a > 1) {
    a = a / 100;
  }
  const toHex = num =>
    num
      .toString(16)
      .padStart(2, '0')
      .toUpperCase();
  const hex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  const opacity = (a * 100).toFixed(0);
  return { hex, opacity };
};

export const hexToRgba = (hex, alpha) => {
  const rgbColors = hex.match(/\w\w/g);
  const [r, g, b] = rgbColors?.map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

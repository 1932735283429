import axios from 'axios';

export const ATENDIMENTOS_URL = 'api';

// CREATE =>  POST: add a new customer to the server
export function createAtendimento(customer) {
  return axios.post(ATENDIMENTOS_URL, { customer });
}

// READ
export function getAllAtendimentos() {
  return axios.get(ATENDIMENTOS_URL);
}

export function getAtendimentoById(customerId) {
  return axios.get(`${ATENDIMENTOS_URL}/${customerId}`);
}

export function findEventos(liveId, userId) {
  return axios.get(`${ATENDIMENTOS_URL}/eventos?videoId=${liveId}&usuarioId=${userId}`);
}

export function findTelemetryInfo(liveId, userId) {
  return axios.get(`${ATENDIMENTOS_URL}/sessoes?videoId=${liveId}&usuarioId=${userId}`);
}

export function findOldLiveInfo(liveId) {
  return axios.get(`${ATENDIMENTOS_URL}/live/l/${liveId}/details`);
}

export function findLiveInfo(liveId) {
  return axios.get(`${process.env.REACT_APP_NOVA_API_URL}/v1/live-streams/${liveId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findAtendimentos(queryParams) {
  return axios.post(`${ATENDIMENTOS_URL}/find`, { queryParams });
}

// UPDATE => PUT: update the customer on the server
export function updateAtendimento(customer) {
  return axios.put(`${ATENDIMENTOS_URL}/${customer.id}`, { customer });
}

// UPDATE Status
export function updateStatusForAtendimentos(ids, status) {
  return axios.post(`${ATENDIMENTOS_URL}/updateStatusForAtendimentos`, {
    ids,
    status
  });
}

import { Avatar, Stack, Tooltip, Typography } from '@material-ui/core';
import React from 'react';

const SpeakerItem = ({ speaker }) => {
  const isDefaultAvatar = speaker.avatar.includes('placeholder');
  return (
    <Stack gap={2} alignItems="center">
      <Tooltip title={speaker.speaker} placement="top">
        <Typography
          sx={{
            color: 'common.white',
            fontSize: 20,
            fontWeight: 600,
            mb: 3,
            width: 100,
            overflow: 'hidden',
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {speaker.speaker}
        </Typography>
      </Tooltip>
      <Avatar src={isDefaultAvatar ? '/media/cogna/default-avatar.png' : speaker.avatar} sx={{ height: 120, mb: 5, width: 120 }} />
    </Stack>
  );
};

export default SpeakerItem;

// Extrai a URL de autorização do ambiente
const getAuthorizationUrl = () => {
  const url = `${process.env.REACT_APP_NOVA_API_URL}/bff/facebook/oauth`;
  return url;
};

// Abre uma janela pop-up para autenticação
const openAuthWindow = (url) => {
  const width = 600;
  const height = 400;
  const left = window.innerWidth / 2 - width / 2;
  const top = window.innerHeight / 2 - height / 2;
  const windowFeatures = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,toolbar=no`;
  return window.open(url, '_blank', windowFeatures);
};

// Verifica periodicamente a existência do token de autenticação
const checkForAuthToken = (authWindow) => {
  const interval = setInterval(() => {
    if (document.cookie.includes('facebook_token')) {
      // Se existe token fecha o popup
      authWindow.close();
      clearInterval(interval);
    }
  }, 1000);
};

// Função principal para obter um token de autenticação
export const getAuthToken = async () => {
  const authUrl = getAuthorizationUrl();  // Obter a URL de autorização
  const authWindow = openAuthWindow(authUrl);  // Abrir janela de autenticação

  // Verificar periodicamente a existência do token de autenticação
  checkForAuthToken(authWindow);
};

import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

const initialAnalitycsState = {
  cdnUse: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'CdnUse',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  liveDetails: {
    hora: new Date().getTime(),
    locations: [],
  },
  peak: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Peak',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  totalTime: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'TotalTime',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  totalReproduction: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'TotalReproduction',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  totalHours: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'TotalHours',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  sessionTime: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'SessionTime',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  spectators: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Spectators',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  spectatorsGraph: {
    listLoading: false,
    error: null,
    graph: { labels: [], series: [] },
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  viewers: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Viewers',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  rebuffering: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Rebuffering',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  initFail: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Erros de inicialização',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  exitBeforeStart: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Saidas antes da inicialização',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  initTime: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: 'Tempo de inicialização',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  videoQuality: {
    listLoading: false,
    error: null,
    entities: [
      {
        name: '240p',
        data: [],
      },
      {
        name: '360p',
        data: [],
      },
      {
        name: '480p',
        data: [],
      },
      {
        name: '720p',
        data: [],
      },
      {
        name: '1280p',
        data: [],
      },
    ],
    cdn: null,
    networkProvider: null,
    state: null,
    so: null,
    total: 0,
  },
  signals: [],
  signalStart: [],
};

export const callTypes = {
  fetchViewers: 'fetch viewers data',
  fetchRebuffering: 'fetch rebuffering data',
  fetchInitFail: 'fetch initialization fail data',
  fetchInitTime: 'fetch initialization time data',
  fetchVideoQuality: 'fetch videoQuality data',
  fetchExitBeforeStart: 'fetch exitbeforestartup data',
};
// totalReproductionetched
// totalTimeFetched
// peakFetched
export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialAnalitycsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.listLoading = false;
    },
    peakFetched: (state, action) => {
      state.peak.error = null;
      state.peak.entities = action.payload;
    },
    sessionTimeFetched: (state, action) => {
      state.sessionTime.error = null;
      state.sessionTime.entities = action.payload;
    },
    totalHoursFetched: (state, action) => {
      state.totalHours.error = null;
      state.totalHours.entities = action.payload;
    },
    totalTimeFetched: (state, action) => {
      state.totalTime.error = null;
      state.totalTime.entities = action.payload;
    },
    totalReproductionFetched: (state, action) => {
      state.totalReproduction.error = null;
      state.totalReproduction.entities = action.payload;
    },
    spectatorsFetched: (state, action) => {
      state.spectators.error = null;
      state.spectators.entities = action.payload;
    },
    spectatorsPizzaGraphFetched: (state, action) => {
      state.spectatorsGraph.error = null;
      state.spectatorsGraph.graph = action.payload;
    },
    cdnUseFetched: (state, action) => {
      state.viewers.error = null;
      state.cdnUse.entities = action.payload;
    },
    viewersFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.entities = action.payload;
    },
    viewersUpdated: (state, action) => {
      state.viewers.error = null;
      if (action.payload[0].data.length === 0) return;
      let dateLimit = DateTime.local().minus({ minutes: 30 }).ts;

      let new_data = action.payload[0].data
        .concat(state.viewers.entities[0].data)
        .filter(elem => {
          return elem[0] > dateLimit;
        });

      state.viewers.entities = [
        {
          name: 'Viewers',
          data: new_data,
        },
      ];
    },
    viewersDataFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.cdn = action.payload.viewersByCdnResponse;
      state.viewers.state = action.payload.viewersByStateResponse;
      state.viewers.so = action.payload.viewersBySOResponse;
      state.viewers.networkProvider =
        action.payload.viewersByNetworkProviderResponse;
    },
    viewersByCdnFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.cdn = action.payload;
    },
    viewersByNetworkProviderFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.networkProvider = action.payload;
    },
    viewersByStateFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.state = action.payload;
    },
    viewersBySoFetched: (state, action) => {
      state.viewers.error = null;
      state.viewers.so = action.payload;
    },
    liveFetched: (state, action) => {
      const data = action.payload;
      state.listLoading = false;
      state.error = null;
      state.liveDetails = data;
    },
    rebufferingFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.entities = action.payload;
    },
    rebufferingUpdated: (state, action) => {
      state.rebuffering.error = null;
      if (action.payload[0].data.length === 0) return;

      let dateLimit = DateTime.local().minus({ minutes: 30 }).ts;

      let new_data = action.payload[0].data
        .concat(state.rebuffering.entities[0].data)
        .filter(elem => {
          return elem[0] > dateLimit;
        });

      state.rebuffering.entities = [
        {
          name: 'Rebuffering',
          data: new_data,
        },
      ];
    },
    rebufferingDataFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.cdn = action.payload.RebufferingByCdnResponse;
      state.rebuffering.state = action.payload.RebufferingByStateResponse;
      state.rebuffering.so = action.payload.RebufferingBySOResponse;
      state.rebuffering.networkProvider =
        action.payload.RebufferingByNetworkProviderResponse;
    },
    rebufferingByCdnFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.cdn = action.payload;
    },
    rebufferingByNetworkProviderFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.networkProvider = action.payload;
    },
    rebufferingByStateFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.state = action.payload;
    },
    rebufferingBySoFetched: (state, action) => {
      state.rebuffering.error = null;
      state.rebuffering.so = action.payload;
    },
    initFailFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.entities = action.payload;
    },
    initFailUpdated: (state, action) => {
      state.initFail.error = null;
      if (action.payload[0].data.length === 0) return;

      let dateLimit = DateTime.local().minus({ minutes: 30 }).ts;

      let new_data = action.payload[0].data
        .concat(state.initFail.entities[0].data)
        .filter(elem => {
          return elem[0] > dateLimit;
        });

      state.initFail.entities = [
        {
          name: 'Falha na inicialização',
          data: new_data,
        },
      ];
    },
    initFailDataFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.cdn = action.payload.InitFailByCdnResponse;
      state.initFail.state = action.payload.InitFailByStateResponse;
      state.initFail.so = action.payload.InitFailBySOResponse;
      state.initFail.networkProvider =
        action.payload.InitFailByNetworkProviderResponse;
    },
    initFailByCdnFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.cdn = action.payload;
    },
    initFailByNetworkProviderFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.networkProvider = action.payload;
    },
    initFailByStateFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.state = action.payload;
    },
    initFailBySoFetched: (state, action) => {
      state.initFail.error = null;
      state.initFail.so = action.payload;
    },

    ExitBeforeStartFetched: (state, action) => {
      state.exitBeforeStart.error = null;
      state.exitBeforeStart.entities = action.payload;
    },
    ExitBeforeStartUpdated: (state, action) => {
      state.exitBeforeStart.error = null;
      if (action.payload[0].data.length === 0) return;

      let dateLimit = DateTime.local().minus({ minutes: 30 }).ts;

      let new_data = action.payload[0].data
        .concat(state.exitBeforeStart.entities[0].data)
        .filter(elem => {
          return elem[0] > dateLimit;
        });

      state.exitBeforeStart.entities = [
        {
          name: 'Saida antes da inicialização',
          data: new_data,
        },
      ];
    },
    ExitBeforeStartDataFetched: (state, action) => {
      state.exitBeforeStart.error = null;
      state.exitBeforeStart.cdn = action.payload.ExitBeforeStartByCdnResponse;
      state.exitBeforeStart.state =
        action.payload.ExitBeforeStartByStateResponse;
      state.exitBeforeStart.so = action.payload.ExitBeforeStartBySOResponse;
      state.exitBeforeStart.networkProvider =
        action.payload.ExitBeforeStartByNetworkProviderResponse;
    },
    ExitBeforeStartByCdnFetched: (state, action) => {
      state.ExitBeforeStart.error = null;
      state.ExitBeforeStart.cdn = action.payload;
    },
    ExitBeforeStartByNetworkProviderFetched: (state, action) => {
      state.ExitBeforeStart.error = null;
      state.ExitBeforeStart.networkProvider = action.payload;
    },
    ExitBeforeStartByStateFetched: (state, action) => {
      state.ExitBeforeStart.error = null;
      state.ExitBeforeStart.state = action.payload;
    },
    ExitBeforeStartBySoFetched: (state, action) => {
      state.ExitBeforeStart.error = null;
      state.ExitBeforeStart.so = action.payload;
    },

    initTimeFetched: (state, action) => {
      state.initTime.error = null;
      if (action.payload.length > 0) state.initTime.entities = action.payload;
    },
    initTimeUpdated: (state, action) => {
      state.initTime.error = null;
      if (action.payload[0].data.length === 0) return;
      let dateLimit = DateTime.local().minus({ minutes: 30 }).ts;

      let new_data = action.payload[0].data
        .concat(state.initTime.entities[0].data)
        .filter(elem => {
          return elem[0] > dateLimit;
        });

      state.initTime.entities = [
        {
          name: 'Tempo de inicialização',
          data: new_data,
        },
      ];
    },
    initTimeDataFetched: (state, action) => {
      state.initTime.error = null;
      state.initTime.cdn = action.payload.initTimeByCdnResponse;
      state.initTime.state = action.payload.initTimeByStateResponse;
      state.initTime.so = action.payload.initTimeBySOResponse;
      state.initTime.networkProvider =
        action.payload.initTimeByNetworkProviderResponse;
    },
    initTimeByCdnFetched: (state, action) => {
      state.initTime.error = null;
      state.initTime.cdn = action.payload;
    },
    initTimeByNetworkProviderFetched: (state, action) => {
      state.initTime.error = null;
      state.initTime.networkProvider = action.payload;
    },
    initTimeByStateFetched: (state, action) => {
      state.initTime.error = null;
      state.initTime.state = action.payload;
    },
    initTimeBySoFetched: (state, action) => {
      state.initTime.error = null;
      state.initTime.so = action.payload;
    },

    videoQualityFetched: (state, action) => {
      state.videoQuality.error = null;
      if (action.payload.length > 0)
        state.videoQuality.entities = action.payload;
    },
    videoQualityDataFetched: (state, action) => {
      state.videoQuality.error = null;
      state.videoQuality.cdn = action.payload.VideoQualityByCdnResponse;
      state.videoQuality.state = action.payload.VideoQualityByStateResponse;
      state.videoQuality.so = action.payload.VideoQualityBySOResponse;
      state.videoQuality.networkProvider =
        action.payload.VideoQualityByNetworkProviderResponse;
    },
    videoQualityByCdnFetched: (state, action) => {
      state.videoQuality.error = null;
      state.videoQuality.cdn = action.payload;
    },
    videoQualityByNetworkProviderFetched: (state, action) => {
      state.videoQuality.error = null;
      state.videoQuality.networkProvider = action.payload;
    },
    videoQualityByStateFetched: (state, action) => {
      state.videoQuality.error = null;
      state.videoQuality.state = action.payload;
    },
    videoQualityBySoFetched: (state, action) => {
      state.videoQuality.error = null;
      state.videoQuality.so = action.payload;
    },
    signalsFetched: (state, action) => {
      state.signals = action.payload;
    },
    signalStartFetched: (state, action) => {
      state.signalStart = action.payload;
    },
    //signalStartFetched
  },
});

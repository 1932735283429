import React from 'react';
import './THEOplayerWrapper.css';

class Player extends React.Component {
  _player = null;
  _el = React.createRef();
  textTrack = null;

  componentDidMount() {
    // props like the source, or the sourcedesciption as a whole can be passed through
    // the can then be used to be called on the player itself
    const { source, onPlay } = this.props;

    const wrapper = document.getElementById('bm-player');

    if (this._el.current) {
      this._player = new window.THEOplayer.Player(this._el.current, {
        libraryLocation: 'https://cdn.myth.theoplayer.com/a2e6496a-2cc2-4800-ae85-6feae325581c',
        license:
          'sZP7IYe6T6fkID0kCKfZ3Ok10lAgFSatIlh-CKao0mke3KhoIDBi3L0zIKB6FOPlUY3zWokgbgjNIOf9flxl3lBL3o46FS5tTSe-3uaoTOz_TQCtFS11CLBZ3u36ISe_06fVfK4_bQgZCYxNWoryIQXzImf90SCL3uhk0Sfi0u5i0Oi6Io4pIYP1UQgqWgjeCYxgflEc3lfr0SRL3l5r3SfcFOPeWok1dDrLYtA1Ioh6TgV6v6fVfKcqCoXVdQjLUOfVfGxEIDjiWQXrIYfpCoj-fgzVfKxqWDXNWG3ybojkbK3gflNWf6E6FOPVWo31WQ1qbta6FOPzdQ4qbQc1sD4ZFK3qWmPUFOPLIQ-LflNWfK1zWDikfgzVfG3gWKxydDkibK4LbogqW6f9UwPkIYz'
      });

      this._player.source = source;
      this._player.addEventListener('play', onPlay);
      const player = this._player;
      function rewind() {
        player.currentTime -= 5;
      }

      function forward() {
        player.currentTime += 5;
      }

      function toggleMute() {
        player.muted = !player.muted;
      }

      function togglePlay() {
        if (player.paused) {
          player.play();
        } else {
          player.pause();
        }
      }

      function preventStandardHotKeyActions(event) {
        event.stopPropagation();
        event.preventDefault();
      }

      function getPressedKey(event) {
        const pressedKey = event.key;
        let action;

        const keyActionsMapper = {
          ' ': togglePlay,
          ArrowLeft: rewind,
          ArrowRight: forward,
          m: toggleMute
        };

        const isNotActionkey = !keyActionsMapper[pressedKey];

        if (isNotActionkey) {
          return;
        }

        action = keyActionsMapper[pressedKey];

        if (action && pressedKey !== 'Control' && pressedKey !== 'Alt' && pressedKey !== 'Shift') {
          action();
          preventStandardHotKeyActions(event); //Stops the default key behaviour like jumping the page with space.
        }
      }

      function playerFocused() {
        if (wrapper.contains(document.activeElement)) {
          document.addEventListener('keydown', getPressedKey);
        } else {
          document.removeEventListener('keydown', getPressedKey);
        }
      }

      const toSeconds = hms => {
        const [hours, minutes, seconds] = hms.split(':');
        const totalSeconds = +hours * 60 * 60 + +minutes * 60 + +seconds.replace(',', '.');
        return totalSeconds;
      };

      if (this.props.subtitles) {
        setTimeout(() => {
          const video = document.querySelector('video');
          this.textTrack = video.addTextTrack('captions', 'Captions', 'pt');
          this.textTrack.mode = 'showing';
          this.props.subtitles.map(subtitle =>
            this.textTrack.addCue(new VTTCue(toSeconds(subtitle.inicio), toSeconds(subtitle.fim), subtitle.texto))
          );
        }, 1000);
      }

      function mouseInPlayer() {
        //Checks if the mouse is inside the player wrapper area
        document.addEventListener('keydown', getPressedKey);
        wrapper.addEventListener('mouseleave', mouseOutPlayer);
      }

      function mouseOutPlayer() {
        //Checks if the mouse leaves the player wrapper area
        wrapper.removeEventListener('mouseleave', mouseOutPlayer);
        document.removeEventListener('keydown', getPressedKey);
      }

      function enableShortcuts(method) {
        switch (method) {
          case 'mouseOver': //If the param reads 'mouseOver' shortcuts are only enabled when the mouse is inside of the player.
            wrapper.addEventListener('mouseenter', mouseInPlayer);
            break;
          case 'playerFocused': //If the param reads 'playerFocused' shortcuts are only enabled when the player is focused.
            document.addEventListener('focus', playerFocused, true);
            break;
          default:
            document.addEventListener('keydown', getPressedKey); //Else player shortcuts are always enabled.
        }
      }
      // setting up the rewind button by setting up a video-js component
      var rewindButton = window.THEOplayer.videojs.getComponent('Button');
      var RewindButton = window.THEOplayer.videojs.extend(rewindButton, {
        constructor: function() {
          rewindButton.apply(this, arguments);
          /* initialize your button */
          // this.el() = created DOM-element

          // add tooltip
          var tooltipSpan = document.createElement('span');
          tooltipSpan.className = 'theo-button-tooltip vjs-hidden';
          tooltipSpan.innerText = 'Go back 10 seconds';
          function toggleTooltip() {
            tooltipSpan.classList.toggle('vjs-hidden');
          }
          this.el().addEventListener('mouseover', toggleTooltip);
          this.el().addEventListener('mouseout', toggleTooltip);
          this.el().appendChild(tooltipSpan);
          this.el().innerHTML += '<img src="https://beyond.spalla.io/player/images/rewind-10s.png" style="width: 18px;height: 18px;">';
        },
        handleClick: () => {
          player.currentTime -= 10;
        },
        buildCSSClass: function() {
          return 'fa spalla-rewind vjs-button'; // insert all class names here
        }
      });
      window.THEOplayer.videojs.registerComponent('RewindButton', RewindButton);
      player.ui.getChild('controlBar').addChild('RewindButton', {});

      // setting up the forward button by setting up a video-js component
      var forwardButton = window.THEOplayer.videojs.getComponent('Button');
      var ForwardButton = window.THEOplayer.videojs.extend(forwardButton, {
        constructor: function() {
          forwardButton.apply(this, arguments);
          /* initialize your button */
          // this.el() = created DOM-element

          // add tooltip
          var tooltipSpan = document.createElement('span');
          tooltipSpan.className = 'theo-button-tooltip vjs-hidden';
          tooltipSpan.innerText = 'Go forward 10 seconds';
          function toggleTooltip() {
            tooltipSpan.classList.toggle('vjs-hidden');
          }
          this.el().addEventListener('mouseover', toggleTooltip);
          this.el().addEventListener('mouseout', toggleTooltip);
          this.el().appendChild(tooltipSpan);
          this.el().innerHTML += '<img src="https://beyond.spalla.io/player/images/forward-10s.png" style="width: 18px;height: 18px;">';
        },
        handleClick: () => {
          player.currentTime += 10;
        },
        buildCSSClass: function() {
          return 'fa spalla-forward vjs-button'; // insert all class names here
        }
      });
      window.THEOplayer.videojs.registerComponent('ForwardButton', ForwardButton);
      player.ui.getChild('controlBar').addChild('ForwardButton', {});
      enableShortcuts('mouseOver');
    }
  }

  componentWillUnmount() {
    if (this._player) {
      this._player.destroy();
    }
  }

  updateCue(cue, { content, newStartTime, newEndTime }) {
    if (this.textTrack) {
      const startTime = newStartTime ?? cue.startTime;
      const endTime = newEndTime ?? cue.endTime;
      const newContent = content || cue.text;
      const newCue = new VTTCue(startTime, endTime, newContent);
      this.textTrack.removeCue(cue);
      this.textTrack.addCue(newCue);
    }
  }

  updateCurrentTime(currentTime) {
    this._player.currentTime = currentTime;
  }

  render() {
    if (this._player) {
      if (this.props.currentTime) {
        this._player.currentTime = this.props.currentTime;
      }
    }
    return (
      <div
        // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
        id="bm-player"
        className={'theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer'}
        // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
        // Which is need by the player
        ref={this._el}
      />
    );
  }
}

export default Player;

/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import { Link } from '@material-ui/core';
import { AuthorizedSection } from '@tshio/react-router-permissions';
import { AsideMenuItem } from '@metronic/layout/components/aside/aside-menu/AsideMenuItem';
import { AsideMenuItemWithSub } from '@metronic/layout/components/aside/aside-menu/AsideMenuItemWithSub';
import { KTSVG } from '@metronic/_helpers';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ProgressDrawer from '../../../../../app/components/SideMenuProgress/SideMenuProgress';
import { useLanguage } from '../../../../../app/i18n/I18Context';

export function AsideMenuList({ layoutProps }) {
  const history = useHistory();
  const { setLocale, locale } = useLanguage();
  const { auth } = useSelector(state => ({ auth: state.auth }), shallowEqual);
  const { user } = auth;
  const userIsOnATeam = user.subConta;
  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    localStorage.removeItem('lastVisitedPage');
    history.push('/logout');
  };
  const userDirectory = user.diretorioRoot;
  return (
    <>
      <ul className={`menu-nav d-flex flex-column h-100 ${layoutProps.ulClasses}`}>
        <AsideMenuItemWithSub to="" title="Conteúdo" icon="/media/svg/icons/Devices/TV1.svg" data-testid="content-options">
          <AuthorizedSection requires="showStreaming">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/streaming" title="Transmissões" hasBullet={true} />}
          </AuthorizedSection>
          <AuthorizedSection requires={['broadcast']}>
            {({ isAuthorized }) =>
              isAuthorized && <AsideMenuItem to="/broadcast" title="Broadcast" hasBullet={true} data-testid="content-options-broadcast" />
            }
          </AuthorizedSection>
          <AuthorizedSection requires="showHistory">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/historic" title="Histórico de transmissões" hasBullet={true} />}
          </AuthorizedSection>
          <AuthorizedSection requires="showVOD">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/vod/dashboard" title="Video on Demand" hasBullet={true} />}
          </AuthorizedSection>
        </AsideMenuItemWithSub>
        <AuthorizedSection requires={['showUsers', 'gerenciarTimes', 'showRoles', 'spalla-dev', 'showFinancial']}>
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title="Gestão" icon="/media/svg/icons/Home/User.svg">
                <AuthorizedSection requires="showUsers">
                  {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/users" title="Usuários" hasBullet={true} />}
                </AuthorizedSection>

                <AsideMenuItem to="/records" title="Registros" hasBullet={true} beta />

                <AuthorizedSection requires="gerenciarTimes">
                  {({ isAuthorized }) => isAuthorized && !userIsOnATeam && <AsideMenuItem to="/teams" title="Times" hasBullet={true} />}
                </AuthorizedSection>

                <AuthorizedSection requires="showRoles">
                  {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/roles" title="Cargos" hasBullet={true} />}
                </AuthorizedSection>

                <AuthorizedSection requires="spalla-dev">
                  {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/permissions" title="Permissões" hasBullet={true} />}
                </AuthorizedSection>

                <AuthorizedSection requires="showFinancial">
                  {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/billing" title="Financeiro" hasBullet={true} />}
                </AuthorizedSection>
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>

        <AuthorizedSection requires={['spalla-dev', 'showStreamingAnalytics', 'spalla_studio']}>
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title="Analytics" icon="/media/svg/icons/Devices/bar_chart.svg">
                <AuthorizedSection requires="showStreamingAnalytics">
                  {({ isAuthorized }) =>
                    isAuthorized && (
                      <AsideMenuItem
                        linkProperty="href"
                        Link={Link}
                        to={`${process.env.REACT_APP_ANALYTICS_URL}vod/${userDirectory}/general?isDir=true&paths=/${userDirectory}`}
                        title="Video on Demand"
                        hasBullet={true}
                      />
                    )
                  }
                </AuthorizedSection>
                <AuthorizedSection requires={['spalla-dev', 'spalla_studio']}>
                  {({ isAuthorized }) =>
                    isAuthorized && (
                      <AsideMenuItem
                        linkProperty="href"
                        Link={Link}
                        to={`${process.env.REACT_APP_ANALYTICS_URL}studio`}
                        title="Spalla Studio"
                        beta
                        hasBullet={true}
                      />
                    )
                  }
                </AuthorizedSection>
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>

        <AsideMenuItemWithSub title="Integração" icon="/media/svg/icons/General/Settings-2.svg">
          <AuthorizedSection requires="api">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/api-keys" title="Chaves de API" hasBullet={true} />}
          </AuthorizedSection>

          <AuthorizedSection requires={['spalla-dev', 'integrations']}>
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/integrations" title="Integrações" hasBullet={true} />}
          </AuthorizedSection>

          <AuthorizedSection requires="showAccountSettings">
            {({ isAuthorized }) => isAuthorized && <AsideMenuItem to="/account/settings" title="Configurações" hasBullet={true} />}
          </AuthorizedSection>
        </AsideMenuItemWithSub>

        <AuthorizedSection requires="spalla-dev">
          {({ isAuthorized }) =>
            isAuthorized && (
              <AsideMenuItemWithSub title="Spalla" icon="/media/svg/icons/Communication/Shield-thunder.svg">
                <AsideMenuItem to="/clients" title="Clientes" hasBullet={true} />
              </AsideMenuItemWithSub>
            )
          }
        </AuthorizedSection>

        <AsideMenuItemWithSub
          title={locale === 'en' ? 'Inglês' : 'Português'}
          icon="/media/svg/icons/Custom/translate.svg"
          style={{ marginTop: 'auto' }}
        >
          <AsideMenuItem active={locale === 'pt-BR'} title="- Português (BR)" onClick={() => setLocale('pt-BR')} />
          <AsideMenuItem active={locale === 'en'} title="- Inglês (EUA)" onClick={() => setLocale('en')} />
        </AsideMenuItemWithSub>

        <li className={`menu-item`} aria-haspopup="true">
          <span className="menu-link btn btn-link">
            <div className="symbol menu-icon symbol-20 symbol-circle mr-2">
              <img
                alt="Pic"
                onError={e => {
                  e.target.src = '/media/placeholder-user.png';
                  e.target.onError = null;
                }}
                src={auth.user.avatar}
              />
            </div>
            <span className="menu-text flex-column align-items-start text-capitalize">
              {auth.user.nome}
              <span className="font-size-xs d-block text-uppercase">{auth.user.cargo}</span>
            </span>
          </span>
        </li>
        <ProgressDrawer />
        <li className={`menu-item`} aria-haspopup="true">
          <button className="menu-link btn btn-link" onClick={logoutClick}>
            <KTSVG path={'/media/svg/icons/Navigation/Sign-out.svg'} className="svg-icon menu-icon" />
            <span className="menu-text">Sair</span>
          </button>
        </li>
      </ul>
    </>
  );
}

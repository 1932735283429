import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    name: yup
      .string()
      .required()
      .matches(/^(?!\s*$)[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/, 'Nome inserido inválido')
  });

export default validationSchema;

import React from 'react';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import { checkIsActive, KTSVG } from '@metronic/_helpers';

const AsideMenuItemWithSub = ({ children, to, title, icon, ...props }) => {
  const { pathname } = useLocation();
  const isActive = checkIsActive(pathname, to);

  return (
    <li
      className={clsx('menu-item menu-item-submenu', {
        'menu-item-open': isActive
      })}
      aria-haspopup="true"
      data-menu-toggle="hover"
      {...props}
    >
      <NavLink className="menu-link menu-toggle" to="/react-bootstrap">
        {icon && <KTSVG path={icon} className="svg-icon menu-icon" />}
        <span className="menu-text">{title}</span>
        <i className="menu-arrow" />
      </NavLink>
      <div className="menu-submenu">
        <ul className="menu-subnav">
          <ul className="menu-subnav">{children}</ul>
        </ul>
      </div>
    </li>
  );
};

export { AsideMenuItemWithSub };

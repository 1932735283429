import React, { useMemo, useState } from 'react';
import {
  AddRounded as AddIcon,
  DeleteRounded as DeleteIcon,
  PriorityHighRounded as PriorityHighIcon,
  WifiProtectedSetupRounded as WifiProtectedSetupIcon,
  CheckRounded as CheckRoundedicon
} from '@material-ui/icons';
import { format } from 'date-fns';
import DesktopRow from './DesktopRow';
import MobileRow from './MobileRow';

const formatDate = date => ({
  date: format(new Date(date), 'dd/MM/yyyy'),
  time: format(new Date(date), 'H:mm:ss')
});

const RecordsRow = ({ data, getEventType, isMobile, sortDirection, setDirection }) => {
  const [open, setOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const createdAt = useMemo(() => formatDate(data.created_at), [data.created_at]);
  const eventType = getEventType(data?.event);
  const iconMappings = {
    CRIAD: { icon: AddIcon, color: 'success.light' },
    AUTORIZAD: { icon: CheckRoundedicon, color: 'success.light' },
    REMOVID: { icon: DeleteIcon, color: 'error.light' },
    ATUALIZAD: { icon: WifiProtectedSetupIcon, color: 'primary.light' }
  };
  const icon = () => {
    const eventData = data.event.toUpperCase();
    for (const key in iconMappings) {
      if (eventData.includes(key)) {
        const { icon: IconComponent, color } = iconMappings[key];
        return (
          <IconComponent
            sx={{ bgcolor: color, color: color === 'error.light' ? 'error.main' : 'primary.main', borderRadius: '50%', height: 24, p: 1, width: 24 }}
          />
        );
      }
    }
    return <PriorityHighIcon sx={{ bgcolor: 'primary.light', color: 'primary.main', borderRadius: '50%', height: 24, p: 1, width: 24 }} />;
  };
  const showMoreButtonLabel = showMore ? 'Mostrar menos' : 'Mostrar mais';
  const recordIsArray = data?.record && Array.isArray(data?.record);
  return (
    <>
      {isMobile ? (
        <MobileRow
          data={data}
          open={open}
          setOpen={setOpen}
          showMore={showMore}
          setShowMore={setShowMore}
          showMoreButtonLabel={showMoreButtonLabel}
          recordIsArray={recordIsArray}
          createdAt={createdAt}
          eventType={eventType}
          icon={icon}
        />
      ) : (
        <DesktopRow
          data={data}
          open={open}
          setOpen={setOpen}
          showMore={showMore}
          setShowMore={setShowMore}
          showMoreButtonLabel={showMoreButtonLabel}
          recordIsArray={recordIsArray}
          createdAt={createdAt}
          eventType={eventType}
          icon={icon}
        />
      )}
    </>
  );
};

export default RecordsRow;

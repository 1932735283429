import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Chat from './Chat';
import Classroom from './Classroom';
import ContextProvider from './Context';
import Register from './Register';

const Routes = () => (
  <ContextProvider>
    <Switch>
      <Route path="/cogna/register" component={Register} />
      <Route path="/cogna/chat" component={Chat} />
      <Route path="/cogna/classroom" component={Classroom} />
    </Switch>
  </ContextProvider>
);

export default Routes;

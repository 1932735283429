import { useFormik } from 'formik';
import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { store } from 'react-notifications-component';
import * as Yup from 'yup';

import notification from '@app/providers/notification';
import * as auth from '../_redux/authRedux';
import { fetchLogin } from '../_redux/authRedux';

function Login() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const LoginSchema = Yup.object().shape({
    login: Yup.string().required(),
    password: Yup.string().required(),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues: {
      login: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values, { setSubmitting }) => {
      enableLoading();
      dispatch(
        fetchLogin(values.login, values.password, loading => {
          setSubmitting(false);
          disableLoading();
          store.addNotification({
            ...notification,
            title: 'Error!',
            message: 'Usuário ou senha estão incorretos',
            type: 'danger',
          });
        }),
      );
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">Entre no Spalla</h3>
        <p className="text-muted font-weight-bold">
          Insira seus dados para acessar sua conta.
        </p>
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Login"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'login',
            )}`}
            name="login"
            {...formik.getFieldProps('login')}
          />
          {formik.touched.login && formik.errors.login ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.login}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Senha"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              'password',
            )}`}
            name="password"
            {...formik.getFieldProps('password')}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-column flex-wrap justify-content-center align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>Entrar</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));

import { useConfirmationDialog } from '@app/components/ConfirmDialog';
import { Stack, Typography } from '@material-ui/core';
import { TaskAlt as TaskAltIcon } from '@material-ui/icons';
import React, { forwardRef, useRef } from 'react';
import CancelIntegrationButton from './CancelIntegrationButton';
import AuthWithGoogleMeet from './AuthWithGoogleMeet';
import { cancelGoogleMeetIntegration } from '../services';
import addNotification from '../../../utils/addNotification';

const GoogleMeetLogoutButton = forwardRef(({ googleMeetQuery, setGoogleMeetIntegrationIsActive, setGoogleMeetImportIsActive, disabled }, ref) => {
  const { isLoading, refetch } = googleMeetQuery;
  const logoutModalRef = useRef(null);
  const getConfirmation = useConfirmationDialog();
  const onSignInWithGoogleMeetHandler = () => {
    logoutModalRef.current.openModal();
  };
  const onConfirmLogoutModal = async () => {
    try {
      await cancelGoogleMeetIntegration();
      await getConfirmation({
        title: (
          <Stack alignItems="center" direction="row" gap={2}>
            <TaskAltIcon color="success" />
            Sucesso
          </Stack>
        ),
        message: (
          <Typography>
            A integração com o <b>Google Meet</b> foi desfeita com sucesso.
          </Typography>
        ),
        cancelButtonProps: { sx: { display: 'none' } },
        confirmButtonProps: { children: 'Fechar' }
      });
      setGoogleMeetImportIsActive(false);
      setGoogleMeetIntegrationIsActive(prevState => !prevState);
      logoutModalRef.current.closeModal();
      await refetch();
    } catch {
      addNotification({
        message: 'Erro ao tentar desfazer a integração.',
        type: 'danger'
      });
    }
  };
  return (
    <>
      <CancelIntegrationButton disabled={disabled || isLoading} onClick={onSignInWithGoogleMeetHandler} />
      <AuthWithGoogleMeet
        ref={logoutModalRef}
        buttonConfirmTitle="Desfazer integração"
        title="Desfazer integração Google Meet"
        description="Ao desfazer a integração com o Google Meet, os conteúdos transferidos anteriormente para a biblioteca do Spalla não serão perdidos. "
        onConfirm={onConfirmLogoutModal}
      />
    </>
  );
});

export default GoogleMeetLogoutButton;
